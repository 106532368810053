import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const CreateStock = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113206", defaultMessage: "Stock" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [material_id, setMaterial_id] = useState(null);
  const [name, setName] = useState(null);
  const [nameEN, setNameEN] = useState(null);
  const [description, setDescription] = useState(null);
  const [qty, setQTY] = useState(0);
  const [convert, setConvert] = useState(1);
  const [unitType,setUnitType] = useState("major");
  const [price, setPrice] = useState(0);
  const [unit, setUnit] = useState([{
    key:"major",
    value:"",
  },{
    key:"minor",
    value:"",
  }]);
  const [role, setRole] = useState("");
  const [listMaterial, setListMaterial] = useState([]);
  const [shop, setShop] = useState([]);
  const [listUnit, setListUnit] = useState([]);
  
  useEffect(() => {
    getListMaterial();
    // getListUnit();
  }, []);

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'
  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: `${baseURL}/inventory/material?search=&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMaterial(res.data.data);
        }
      })
      .catch((err) => {});
  };
  const onSubmit = async () => {
    
    if (qty == undefined || qty == "" || qty == null) {
      return AlertError("Please insert quantity");
    }
    if (material_id == undefined || material_id == "" || material_id == null) {
      return AlertError("Please insert material");
    }
    // const shop_id = localStorage.getItem('shop_id')
    // const branch_id = localStorage.getItem('branch_id')
    var events = {
      // shop_id: shop_id,
      // branch_id: branch_id,
      material_id: material_id,
      price:price,
      quantity: qty,
      method:"import",
      unit_type:unitType,
      reason:description
    };
    let apiUrls = `${baseURL}/inventory`;
    let token = localStorage.getItem("Token");
    console.log(events)
    Swal.fire({
      title: "",
      text: "Confirm Create",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            Swal.fire("Create !", "Your can not create. !! " + err, "error");
          });
      }
    });

    return false;
  };

  const addPermission = () => {
    let temp = {
      page: "",
      page_id: "",

      permission: [],
    };
    setData((data) => [...data, temp]);
  };

  const DeleteQuestion = (index) => {
    var array = [...data]; // make a separate copy of the array
    array.splice(index, 1);
    setData(array);
  };

  const getListUnit = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit?search=&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnit(res.data.data);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113275",
            defaultMessage: "Create Stock",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          

          

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113202",
                defaultMessage: "Material",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-unit"
              options={listMaterial}
              // freeSolo
              disableClearable
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => {
                setMaterial_id(newValue.id);
                setUnit([{
                  key:"major",
                  value:newValue.major_unit_name,
                 
                },{
                  key:"minor",
                  value:newValue.minor_unit_name,
                }])
                setConvert(newValue.qty_out_used)
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>
          
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113276",
                defaultMessage: "Price (Baht)",
              })}
              {" "}:{" "}
            </p>
            <input
              type="number"
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            {intl.formatMessage({
                id: "113235",
                defaultMessage: "Unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              key={JSON.stringify(unit)}
              id="grouped-unit"
              options={unit}
              disableClearable
              getOptionLabel={(option) => option.value}
              //getOptionSelected={(option, value) => option.key == value.key}
              //value={item.groupName}
              onChange={(event, option) => {
                console.log(option);
                setUnitType(option.key);
                // setUsedType(key);
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>


          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "999999999999",
                defaultMessage: "Convert",
              })}
              {" "}:{" "}
            </p>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>{unitType == "major" ? `1:${convert}` :  `${convert}:1`}</p>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113221",
                defaultMessage: "Unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={qty}
              onChange={(e) => setQTY(e.target.value)}
              autoComplete="off"
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            {intl.formatMessage({
                id: "113224",
                defaultMessage: "Reason",
              })}
            
            </p>
            <textarea
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/Stock">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113038", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
