import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL } from "../../../service/API.js";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import "../../component.css";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
export const CreatePromotion = () => {
  const { user } = useSelector((state) => state.auth);
  const subheader = useSubheader();
  subheader.setTitle("Promotion");
  const intl = useIntl();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [promotionTypeId, setPromotionTypeId] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [discountTypeId, setDiscountTypeId] = useState(null);
  const [discountTargetId, setDiscountTargetId] = useState(null);
  const [promotionValue, setPromotionValue] = useState(0);
  const [promotionName, setPromotionName] = useState(null);
  const [promotionDetail, setPromotionDetail] = useState(null);
  const [isPromotionLimit, setIsPromotionLimit] = useState(false);
  const [promotionLimit, setPromotionLimit] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountLimit, setDiscountLimit] = useState(0);
  const [isAutoApply, setIsAutoApply] = useState(false);
  const [applyWeight, setApplyWeight] = useState(1);
  const [showItem, setShowItem] = useState(false);
  const [showResultItem, setShowResultItem] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [menuGroup, setMenuGroup] = useState([]);
  const [menuGroupSelected, setMenuGroupSelected] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [menuSelected, setMenuSelected] = useState([]);
  const [groupMenuMap, setGroupMenuMap] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [promotionSet, setPromotionSet] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [originalGroupMenuMap, setOriginalGroupMenuMap] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [promotionNameTH, setPromotionNameTH] = useState("");
  const [promotionNameEN, setPromotionNameEN] = useState("");
  const [promotionDescTH, setPromotionDescTH] = useState("");
  const [promotionDescEN, setPromotionDescEN] = useState("");
  const [amountValue, setAmountValue] = useState(0);

  const [resultSelected, setResultSelected] = useState([]);
  const [eventPromotion, setEventPromotion] = useState([]);
  const [eventDiscount, setEventDiscount] = useState([]);

  const [expanded, setExpanded] = useState(null);

  const steps = [
    "PROMO TYPE",
    "DISCOUNT TYPE",
    "SET ITEMS",
    "SETTING",
    "SUMMARY",
  ];

  useEffect(() => {
    getGroup();
    getMenu();
    getPromotion();
    getDiscount();
  }, []);
  const AlertError = (message) => {
    Swal.fire("!", message, "error");
  };

  function validateDateTime(startDate, expiryDate) {
    console.log("validateDateTime =====> ", startDate, expiryDate);
    const errors = [];
    const openTimeDate = startDate;
    const closeTimeDate = expiryDate;
    if (openTimeDate > closeTimeDate) {
      AlertError(
        `Error: start time (${openTimeDate}) is greater than expiry time (${closeTimeDate}) `
      );
      return false; // Stop further validation if an error is found
    }
    if (openTimeDate === closeTimeDate) {
      AlertError(
        `Error: Open time (${openTimeDate}) is the same time of close time (${closeTimeDate}) `
      );
      return false; // Stop further validation if an error is found
    }
    if (
      openTimeDate === null ||
      openTimeDate === undefined ||
      closeTimeDate === null ||
      closeTimeDate === undefined
    ) {
      AlertError(`Error: Open time  or closeTime is invalid `);
      return false; // Stop further validation if an error is found
    }

    // for (const day of data) {
    //   if(day.is_closed == 1){
    //     return true;
    //   }else{
    //     for (const slot of day.time_slot) {
    //       if(slot.is_delete ==1 ){
    //         return true;
    //       }else{
    //         const openTimeDate = new Date(`1970-01-01T${slot.open_time}`);
    //         const closeTimeDate = new Date(`1970-01-01T${slot.close_time}`);

    //         if (openTimeDate > closeTimeDate) {
    //           AlertError(`Error: Open time (${slot.open_time}) is greater than close time (${slot.close_time})  on ${day.datename_en}`);
    //           return false; // Stop further validation if an error is found
    //         }
    //         if (openTimeDate === closeTimeDate) {
    //           AlertError(`Error: Open time (${slot.open_time}) is the same time of close time (${slot.close_time})  on ${day.datename_en}`);
    //           return false; // Stop further validation if an error is found

    //         }
    //         if (slot.open_time === null ||slot.open_time === undefined || slot.close_time === null || slot.close_time === undefined ) {
    //           AlertError(`Error: Open time  or closeTime is invalid on ${day.datename_en}`);
    //           return false; // Stop further validation if an error is found
    //         }

    //       }

    //     }
    //     for (let i = 0; i < day.time_slot.length - 1; i++) {
    //       if(day.time_slot.is_delete == 1){
    //         return true;

    //       }else{
    //         const currentCloseTime = new Date(`1970-01-01T${day.time_slot[i].close_time}`);
    //         const nextOpenTime = new Date(`1970-01-01T${day.time_slot[i + 1].open_time}`);

    //         if (currentCloseTime > nextOpenTime) {
    //             AlertError(`Error: Close time (${day.time_slot[i].close_time}) overlaps with open time (${day.time_slot[i + 1].open_time}) on ${day.datename_en}`);
    //             return false; // Stop further validation if an error is found
    //         }

    //       }

    //   }
    // }
    // }
    return true; // Validation passed
  }

  const getPromotion = async () => {
    let apiUrls = `${baseURL}/management/promotionType`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((promotion) => ({
          text: promotion.name_en,
          description: promotion.desc_en,
          promotion_type_id: promotion.promotion_type_id,
          icon:
            promotion.promotion_type_id === 1
              ? "./media/icon/discount.jpg"
              : "./media/icon/present.jpg",
          options:
            promotion.options?.map((option) => ({
              text: option.name_en,
              promotion_type_id: option.promo_type_id,
            })) || [],
        }));
        setEventPromotion(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDiscount = async () => {
    let apiUrls = `${baseURL}/management/discountType`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((discount) => ({
          title: discount.name_en,
          detail: discount.desc_en,
          discount_type_id: discount.discount_type_id,
          icon:
            discount.discount_type_id === 3
              ? "./media/icon/fixedprice.jpg"
              : "./media/icon/percent.jpg", // เพิ่ม icon ตาม discount_type_id
          options: discount.options.map((option) => ({
            text: option.name_en,
            detail: option.description_en,
            discount_target_id: option.discount_target_id,
          })),
        }));
        setEventDiscount(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/group?limit=${10000}&page=1`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((group) => ({
          value: group.id,
          label: group.group_name,
        }));
        setMenuGroup(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMenu = async () => {
    let apiUrls = `${baseURL}/management/menu?limit=${10000}&page=1`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data;
        setMenuItems(newState);

        let groupMenuMap = {};
        newState.forEach((menu) => {
          if (!groupMenuMap[menu.group_id]) {
            groupMenuMap[menu.group_id] = [];
          }
          groupMenuMap[menu.group_id].push(menu);
        });
        // Update item_count for each group
        setMenuGroup((prevMenuGroup) =>
          prevMenuGroup.map((group) => ({
            ...group,
            item_count: groupMenuMap[group.value]
              ? groupMenuMap[group.value].length
              : 0,
          }))
        );
        setGroupMenuMap(groupMenuMap);
        setOriginalGroupMenuMap(groupMenuMap); // Store original map for reset
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNextStep = () => {
    if (currentStep === 2 && (!discountTypeId || !discountTargetId)) {
      return Swal.fire(
        "Create !",
        "You must select a discount type and target.",
        "error"
      );
    }
    if (currentStep === 3 && promotionSet.length === 0) {
      return Swal.fire(
        "Create !",
        "You must select at least one item.",
        "error"
      );
    }
    if (currentStep === 4) {
      const startDateTime = new Date(startDate);
      const expiryDateTime = new Date(expiryDate);

      if (expiryDateTime <= startDateTime) {
        return Swal.fire(
          "Invalid Dates",
          "Expiry date must be after start date.",
          "error"
        );
      }
    }
    console.log("Promotion Type ID:", promotionTypeId);
    console.log("Discount Type ID:", discountTypeId);
    console.log("Discount Target ID:", discountTargetId);
    console.log("Promotion Set:", promotionSet);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCurrentStep(currentStep + 1);
  };

  const handleBackStep = () => {
    if (currentStep > 1) {
      if (currentStep === 2) {
        setDiscountTypeId(null);
        setDiscountTargetId(null);
      } else if (currentStep === 3) {
        setPromotionSet([]);
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setCurrentStep(currentStep - 1);
    }
  };

  const handlePromotionTypeSelect = (promotion_type_id) => {
    const selectedPromotion = eventPromotion.find(
      (promotion) => promotion.promotion_type_id === promotion_type_id
    );
    setPromotionTypeId(promotion_type_id);
    setSelectedOptionId(null); // Clear selectedOptionId when selecting a new promotion type
    if (selectedPromotion.options && selectedPromotion.options.length > 0) {
      setExpanded(promotion_type_id);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleOptionSelect = (option_id) => {
    setSelectedOptionId(option_id);
    setPromotionTypeId(option_id); // Set promotionTypeId to the selected option's ID
  };

  const handleDiscountTypeSelect = (discount_type_id, discount_target_id) => {
    setDiscountTypeId(discount_type_id);
    setDiscountTargetId(discount_target_id);
  };

  const handleMenuSelect = (menuId) => {
    let newPromotionSet = [...promotionSet];
    if (newPromotionSet.includes(menuId)) {
      newPromotionSet = newPromotionSet.filter((id) => id !== menuId);
    } else {
      newPromotionSet.push(menuId);
    }
    setPromotionSet(newPromotionSet);
  };

  const handleCategorySelect = (groupId) => {
    let newPromotionSet = [...promotionSet];
    if (newPromotionSet.includes(groupId)) {
      newPromotionSet = newPromotionSet.filter((id) => id !== groupId);
    } else {
      newPromotionSet.push(groupId);
    }
    setPromotionSet(newPromotionSet);
  };

  const toggleExpand = (index) => {
    if (expanded === index) {
      setExpanded(null);
      setSelectedOptionId(null); // Clear selectedOptionId when collapsing
    } else {
      setExpanded(index);
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setGroupMenuMap(originalGroupMenuMap);
      setExpanded(null);
    } else {
      const filteredMenu = {};
      Object.keys(originalGroupMenuMap).forEach((groupId) => {
        filteredMenu[groupId] = originalGroupMenuMap[groupId].filter((menu) =>
          menu.item_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setGroupMenuMap(filteredMenu);
      const firstGroupWithResults = Object.keys(filteredMenu).find(
        (groupId) => filteredMenu[groupId].length > 0
      );
      setExpanded(firstGroupWithResults || null); // Automatically expand the first group with search results
    }
  };

  const renderMenuItem = (menu) => {
    let discount = 0;
    if (discountTypeId === 3) {
      discount = discountValue;
    } else if (discountTypeId === 4) {
      discount = menu.price * (amountValue || 1) * (discountValue / 100);
    }
    const discountedPrice = menu.price * (amountValue || 1) - discount;
    const displayDiscountedPrice = discountedPrice > 0 ? discountedPrice : 0;

    const imageSrc = menu.item_image_path
      ? menu.item_image_path
      : "./media/icon/slash.jpg";

    return (
      <div key={menu.id} style={menuCardStyle}>
        <img
          src={imageSrc}
          alt={menu.item_name}
          style={menuImageStyle}
          onError={(e) => {
            e.target.src = "./media/icon/slash.jpg";
          }}
        />
        <p style={menuNameStyle}>
          {menu.item_name}
          {amountValue > 0 && ` (${amountValue} items)`}
        </p>
        <p style={originalPriceStyle}>
          <s>฿{menu.price * (amountValue || 1)}</s>
        </p>
        <p style={discountedPriceStyle}>฿{displayDiscountedPrice.toFixed(2)}</p>
      </div>
    );
  };

  const menuCardStyle = {
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    margin: "10px",
    width: "150px",
  };

  const menuImageStyle = {
    width: "100px",
    height: "100px",
    borderRadius: "8px",
  };

  const menuNameStyle = {
    fontWeight: "bold",
    margin: "10px 0",
  };

  const originalPriceStyle = {
    color: "#AAAAAA",
  };

  const discountedPriceStyle = {
    fontWeight: "bold",
    color: "#000",
  };
  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create this promotion?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formatDate = (date) => {
          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");
          const hours = String(d.getHours()).padStart(2, "0");
          const minutes = String(d.getMinutes()).padStart(2, "0");
          const seconds = String(d.getSeconds()).padStart(2, "0");
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
        if (!validateDateTime(formatDate(startDate), formatDate(expiryDate))) {
          return;
        }

        const payload = {
          shop_id: localStorage.getItem("shop_id"),
          branch_id: localStorage.getItem("branch_id"),
          promotion_name_th: promotionNameTH,
          promotion_name_en: promotionNameEN,
          promotion_desc_th: promotionDescTH,
          promotion_desc_en: promotionDescEN,
          start_date_time: formatDate(startDate),
          expiry_date_time: formatDate(expiryDate),
          promotion_type_id: String(promotionTypeId),
          discount_type_id: String(discountTypeId),
          discount_target_id: String(discountTargetId),
          promotion_set: promotionSet.map((id) => ({ object_id: id })),
          discount_value: discountTypeId === 3 ? discountValue : 0,
          discount_percent: discountTypeId === 4 ? discountValue : 0,
          amount_value: promotionTypeId === 8 ? amountValue : 0,
        };

        let token = localStorage.getItem("Token");

        axios
          .post(`${baseURL}/management/promotionnew`, payload, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: "Promotion created successfully.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              history.push("/Promotion");
            });
          })
          .catch((err) => {
            Swal.fire("Error!", "Failed to create promotion.", "error");
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Stepper
          style={{ backgroundColor: "#EEF0F8" }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className="d-flex justify-content-center">
        {currentStep === 1 && (
          <Card style={{ width: "80%" }}>
            <CardHeader
              title={intl.formatMessage({
                id: "113441",
                defaultMessage: "Select Promotion Type",
              })}
            ></CardHeader>
            <CardBody>
              <div className="promotion-selection">
                {eventPromotion.length > 0 &&
                  eventPromotion.map((data) => (
                    <div key={data.promotion_type_id}>
                      <div
                        className="radio-card"
                        onClick={() =>
                          handlePromotionTypeSelect(data.promotion_type_id)
                        }
                      >
                        <div className="radio-content">
                          <div className="radio-icon">
                            <img
                              style={{ borderRadius: "50%" }}
                              src={data.icon}
                              alt={data.text}
                            />
                          </div>
                          <div className="radio-details">
                            {data.text}
                            <p>{data.description}</p>
                          </div>
                          {data.options && data.options.length > 0 && (
                            <div className="radio-arrow">
                              <i
                                className={`fa ${
                                  expanded === data.promotion_type_id
                                    ? "fa-chevron-down"
                                    : "fa fa-chevron-right"
                                }`}
                              ></i>
                            </div>
                          )}
                        </div>
                        <input
                          type="radio"
                          className="planes-radio"
                          name="promotionType"
                          checked={
                            promotionTypeId === data.promotion_type_id ||
                            selectedOptionId === data.promotion_type_id
                          }
                          readOnly
                        />
                      </div>
                      {expanded === data.promotion_type_id &&
                        data.options &&
                        data.options.length > 0 && (
                          <div className="discount-type-selection">
                            {data.options.map((option) => (
                              <div
                                className="radio-card"
                                key={option.promotion_type_id}
                                onClick={() =>
                                  handleOptionSelect(option.promotion_type_id)
                                }
                              >
                                <div style={{ width: "70px" }}></div>
                                <input
                                  type="radio"
                                  name="optionType"
                                  checked={
                                    selectedOptionId ===
                                    option.promotion_type_id
                                  }
                                  readOnly
                                />
                                <div className="radio-content">
                                  <div className="radio-details">
                                    <strong>{option.text}</strong>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                variant="primary"
                                onClick={handleNextStep}
                                disabled={!selectedOptionId}
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            </CardBody>
          </Card>
        )}
        {currentStep === 2 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Select Type"></CardHeader>
            <CardBody>
              {eventDiscount.map((group, groupIndex) => (
                <div key={groupIndex}>
                  <div
                    className="group-header"
                    onClick={() => toggleExpand(groupIndex)}
                  >
                    <div
                      className="radio-card"
                      style={{ borderBottom: "none" }}
                    >
                      <div className="radio-content">
                        <div className="radio-icon">
                          <img
                            style={{ borderRadius: "50%" }}
                            src={group.icon}
                            alt={group.text}
                          />
                        </div>
                        <div className="radio-details">
                          <h5>{group.title}</h5>
                          <p>{group.detail}</p>
                        </div>
                        <div className="radio-arrow">
                          <i
                            className={`fa ${
                              expanded === groupIndex
                                ? "fa-chevron-down"
                                : "fa fa-chevron-right"
                            }`}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {expanded === groupIndex && group.options.length > 0 && (
                    <div className="discount-type-selection">
                      {group.options.map((option) => (
                        <div
                          className="radio-card"
                          key={option.discount_target_id}
                          onClick={() =>
                            handleDiscountTypeSelect(
                              group.discount_type_id,
                              option.discount_target_id
                            )
                          }
                        >
                          <div style={{ width: "70px" }}></div>
                          <input
                            type="radio"
                            name="discountType"
                            checked={
                              discountTypeId === group.discount_type_id &&
                              discountTargetId === option.discount_target_id
                            }
                            readOnly
                          />
                          <div className="radio-content">
                            <div className="radio-details">
                              <strong>{option.text}</strong>
                              <p>{option.detail}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </CardBody>
          </Card>
        )}
        {currentStep === 3 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Set Discount Items">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Text Search"
                    style={{ width: "200px" }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {menuGroup.map((group) => (
                <div key={group.value}>
                  <div className="group-header">
                    <div
                      className="radio-card"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "none",
                      }}
                    >
                      <div className="radio-content">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {discountTargetId === 6 && (
                            <input
                              type="checkbox"
                              checked={promotionSet.includes(group.value)}
                              style={{ marginTop: "-5px" }}
                              onChange={() => handleCategorySelect(group.value)}
                            />
                          )}
                          <div className="radio-content">
                            <h5
                              style={{
                                fontWeight: "bold",
                                marginLeft:
                                  discountTargetId === 6 ? "10px" : "0px",
                              }}
                            >
                              {group.label}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div
                        className="item-count"
                        style={{ fontWeight: "bold" }}
                        onClick={() => toggleExpand(group.value)}
                      >
                        <p style={{ marginRight: "20px" }}>
                          {group.item_count} items
                        </p>
                      </div>
                      <div
                        className="radio-arrow"
                        onClick={() => toggleExpand(group.value)}
                      >
                        <i
                          className={`fa ${
                            expanded === group.value
                              ? "fa-chevron-down"
                              : "fa fa-chevron-right"
                          }`}
                          style={{ marginTop: "-5px" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  {expanded === group.value &&
                    groupMenuMap[group.value] &&
                    groupMenuMap[group.value].length > 0 && (
                      <div className="discount-item-selection">
                        {groupMenuMap[group.value].map((menu) => (
                          <div
                            className="checkbox-card"
                            key={menu.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "70px" }}></div>
                              {discountTargetId === 5 && (
                                <input
                                  type="checkbox"
                                  checked={promotionSet.includes(menu.id)}
                                  onChange={() => handleMenuSelect(menu.id)}
                                />
                              )}
                              <div
                                className="checkbox-content"
                                style={{
                                  marginLeft:
                                    discountTargetId === 5 ? "30px" : "100px",
                                }}
                              >
                                <strong>{menu.item_name}</strong>
                                <p style={{ color: "#AAAAAA" }}>
                                  ฿{menu.price}
                                </p>
                              </div>
                            </div>
                            {menu.item_image_path ? (
                              <img
                                src={menu.item_image_path}
                                alt={menu.item_name}
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginRight: "55px",
                                }}
                                onError={(e) => {
                                  e.target.src = "./media/icon/slash.jpg";
                                }} // ดักการโหลดภาพไม่สำเร็จ
                              />
                            ) : (
                              <img
                                src="./media/icon/slash.jpg"
                                alt={menu.item_name}
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginRight: "55px",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </CardBody>
          </Card>
        )}
        {currentStep === 4 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Summary">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Summary</h5>
              </div>
            </CardHeader>
            <CardBody>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  <h6>
                    Discount (
                    {discountTypeId === 3 ? "Fixed Price" : "Percentage"})
                  </h6>
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={discountValue}
                  onChange={(e) => setDiscountValue(e.target.value)}
                />
              </div>
              {promotionTypeId === 8 && (
                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    {intl.formatMessage({
                      id: "quantity_require_for_bundle",
                      defaultMessage: "Quantity require for bundle",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={amountValue}
                    onChange={(e) => setAmountValue(e.target.value)}
                  />
                </div>
              )}
              <div>
                <h6>Discount target</h6>
                <div className="d-flex flex-wrap">
                  {discountTargetId === 6
                    ? promotionSet.map((groupId) =>
                        groupMenuMap[groupId]?.map((menu) =>
                          renderMenuItem(menu)
                        )
                      )
                    : promotionSet.map((id) => {
                        const selectedMenu =
                          menuItems.find((m) => m.id === id) || {};
                        return renderMenuItem(selectedMenu);
                      })}
                </div>
              </div>
              <div className="mt-4">
                <h6>Promotion Details:</h6>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "promotion_name_th",
                      defaultMessage: "Promotion Name (TH)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionNameTH}
                    onChange={(e) => setPromotionNameTH(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "promotion_name_en",
                      defaultMessage: "Promotion Name (EN)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionNameEN}
                    onChange={(e) => setPromotionNameEN(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "promotion_desc_th",
                      defaultMessage: "Promotion Description (TH)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionDescTH}
                    onChange={(e) => setPromotionDescTH(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "promotion_desc_en",
                      defaultMessage: "Promotion Description (EN)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionDescEN}
                    onChange={(e) => setPromotionDescEN(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "start_date_time",
                      defaultMessage: "Start Date",
                    })}
                    :{" "}
                  </p>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    className="form-control"
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "expiry_date_time",
                      defaultMessage: "Expiry Date",
                    })}
                    :{" "}
                  </p>
                  <DatePicker
                    selected={expiryDate}
                    onChange={(date) => setExpiryDate(date)}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    className="form-control"
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="secondary"
                  onClick={handleBackStep}
                  style={{ marginRight: "10px" }}
                >
                  Back
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      {currentStep > 1 && currentStep < 4 && (
        <div className="d-flex justify-content-center mt-3">
          <Button
            variant="secondary"
            onClick={handleBackStep}
            style={{ marginRight: "10px" }}
          >
            Back
          </Button>
          <Button variant="primary" onClick={handleNextStep}>
            Next
          </Button>
        </div>
      )}
    </>
  );
};
