import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";

export const EditPurchasePlan = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "99999999" ,defaultMessage:"Purchase Plan"})
  );
  const history = useHistory();

  const [state, setState] = useState(true);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [qty, setQty] = useState(null);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('')
  const [listMaterial, setListMaterial] = useState([]);

  const handleChangeItem = (event, values) => {
    console.log(event, values);
    if (values) {
      setItem(values);
    } else {
      setItem(null);
    }
  };

  useEffect(() => {
    getListMaterial();
    getPlan();
    getPlanItems();
  }, []);

  const getPlan = async () => {
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: `${baseURL}/purchase/plan?filter=${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
            setDescription(res.data.data.name);
        }
      })
      .catch((err) => {});
  };

  
  const getPlanItems = async () => {
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: `${baseURL}/purchase/plan?id=${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
            setListData(res.data.data);
        }
      })
      .catch((err) => {});
  };



  const getListMaterial = async () => {
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: `${baseURL}/inventory/material?search=&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListMaterial(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      dataField: "material_id",
      text: "ID",
    },

    {
      dataField: "name",
      text: "Name",
    },

    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem(rowIndex)}
            >
              {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
            </button>
          </div>
        );
      },
    },
  ];

  const deleteItem = async(rowIndex) => {
    let temp = []
    console.log(listData)
    var array = [...listData]; // make a separate copy of the array
    array.splice(rowIndex, 1);
    setListData(array);
  }
  
  const AlertError = (message) => {
    Swal.fire("Create Delivery Note!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleAdd = () => {
   
      let temp = listData
      temp.push({
        index: maxIndex+1,
        name:item.name,
        material_id:item.id,
        quantity: qty,
      })
      setListData(temp)
      setMaxIndex(maxIndex+1)
      setQty(0)
      setItem(null)
      console.log(listData)
  }

  const handleSend = async () => {
    /*if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }*/
    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
    let now= new Date()
    var events = {
        id:props.location.state.row.id,
        name:description,
      orders: listData,
  }

    let apiUrls = `${baseURL}/purchase/plan`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Create Plan success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Create Plan . !! " + err,
          "error"
        );
      });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "99999999999",
            defaultMessage: "Edit Plan",
          })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "99999999" ,defaultMessage:"Name" })}:{" "}
              </p>
              <input
                name="table_name"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={description}
                onChange={(table_name) => setDescription(table_name.target.value)}
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  options={listMaterial}
                  freeSolo
                  disableClearable
                  getOptionLabel={(option) => `${option.name} - ${option.major_unit_name}`}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={handleChangeItem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        borderWidth: 0,
                      }}
                      label="item"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={qty}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setQty(e.target.value)
                }
              />
              <button
                className="ml-2 btn btn-primary"
                onClick={handleAdd}
              >
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>

            <hr />            
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/PurchasePlan">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "107016",
                    defaultMessage: "Cancel",
                  })}
                </button>
              </Link>
              <button className="btn btn-primary" onClick={() => handleSend()}>
                {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
              </button>
            </div>
        </CardBody>
      </Card>
    </div>
  );
};
