import React from "react";
import { Link } from "react-router-dom";

export default function NotFound({ isFetch, title, description }) {
  return (
    <div
      className="bg-white w-100 h-100 d-flex align-items-center justify-content-center"
      // style={{ height: "100vh" }}
    >
      <div className="text-center">
        <div className="d-flex flex-column mb-3">
          <div className="d-flex justify-content-center mb-3">
            <img
              style={{ width: "71px", height: "71px" }}
              src="/media/icon/promotion.jpg"
              alt="promotion picture"
            />
          </div>
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: 500 }}>
            {title}
          </p>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#7E8299",
            }}
          >
            {description}
          </p>
        </div>
        {isFetch && (
          <Link to="/CreatePromotionNew">
            <button
              type="button"
              className="btn btn-primary"
              style={{ fontSize: "14px" }}
            >
              Create Promotion
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}
