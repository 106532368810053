import React from "react";
// import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import { useIntl } from "react-intl";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext.js";

const useStyles = makeStyles({
  radioContainer: {
    height: "auto",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    paddingRight: "24px",
    paddingLeft: "24px",
    paddingTop: "19px",
    paddingBottop: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  iconContainer: {
    width: "46px",
    height: "46px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "24px",
    backgroundColor: "#F3F2F7",
    borderRadius: "8px",
    flexShrink:0,
    // width: "100%", height: "auto", minWidth: "50px", maxWidth: "70px"
  },
  icon: {
    // width: "100%",
    // height: "auto",
    width: "30px",
    height: "30px",
    // borderRadius: "50%",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 0,
    paddingRight: "16px",
    paddingBottom: "19px",
    
  },
  description: {
    color: "#6c757d",
    fontSize: "14px",
  },
  formControlLabel: {
    margin: 0, 
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", 
  },
});

function StyledRadio(props) {
  return (
    <Radio
      {...props}
      disableRipple
      color="default"
      icon={
        <span
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            border: "2px solid #b0b0b0", // Gray border when unchecked
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      checkedIcon={
        <span
          style={{
            borderRadius: "50%",
            width: 20,
            height: 20,
            backgroundColor: "#1a73e8", // Blue color when checked
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              width: 10,
              height: 10,
              backgroundColor: "#ffffff", // White dot in the center
              borderRadius: "50%",
            }}
          />
        </span>
      }
    />
  );
}

export default function CurrentStep1() {
  const classes = useStyles();

  const intl = useIntl();

  const {
    eventPromotion,
    promotionTypeId,
    handlePromotionTypeSelect,
  } = useCreatePromotionContext();

  return (
    <div className="d-flex flex-column">
      <Card className="mw-100">
        <CardHeader
          title={intl.formatMessage({
            id: "113441",
            defaultMessage: "Select Promotion Type",
          })}
        ></CardHeader>
        <CardBody>
          <div
            className="row"
            style={{ display: "flex", alignItems: "stretch", flexWrap: "wrap"}}
          >
            {eventPromotion.map((data) => (
              <div
                className="col-12 col-md-6 mb-3 d-flex"
                key={data.promotion_type_id}
                style={{ display: "flex" }}
              >
                <Box
                  className={classes.radioContainer}
                  onClick={() =>
                    handlePromotionTypeSelect(data.promotion_type_id)
                  }
                >
                  <div className={classes.labelContainer}>
                    <div className={classes.iconContainer} 
                    // style={{ width: "100%", height: "auto", minWidth: "50px", maxWidth: "70px"}}
                    > 
                      <img
                        alt={data.text}
                        src={data.icon}
                        className={classes.icon}
                        
                      />
                    </div>
                    <div >
                      <Typography
                        variant="body1"
                        component="div"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          textAlign: "left", // จัดข้อความให้อยู่ตรงกลาง
                        }}
                      >
                        {data.text}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.description}
                        style={{ textAlign: "left" }} // จัดข้อความให้อยู่ตรงกลาง
                      >
                        {data.description}
                      </Typography>
                    </div>
                  </div>
                  <FormControlLabel
                  // style={{textAlign:"center"}}
                  // className="d-flex justify-content-center"
                  classes={{ root: classes.formControlLabel }}
                    control={
                      <StyledRadio
                        checked={promotionTypeId === data.promotion_type_id}
                        readOnly
                      />
                    }
                    label=""
                  />
                </Box>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
