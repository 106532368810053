import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext";
import moment from "moment-timezone";
import "moment/locale/th";
import { Photo } from "@material-ui/icons";

const useStyles = makeStyles({
  customBadge: {
    border: "1px solid #DADEEA",
    padding: "12px 16px",
    borderRadius: "23px",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f9fa",
    fontFamily: "Sarabun, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18.2px",
    color: "#333",
  },
});

export default function CurrentStep5() {
  const {
    stateItem_image_path,
    promotionName,
    promotionDescription,
    couponType,
    purchaseTypes,
    orderQuantity,
    menuItems,
    selectedOrderItems,
    groupMenuMap,
    selectedOrderGroups,
    selectedTargetGroups,
    selectedTargetItems,
    targetQuantity,
    startDate2,
    expiryDate2,
    dayType2,
    timeType2,
    discountTypeId,
    targetFixedPrice,
    targetPercent,
    discountTargetId,
    discountConditionId,
    promotionTypeId,
    checkedAutoApply,
    selectedButton,
    checkedIncludeOption,
    checkedRedemption,
    redemptionDefault,
    checkedItemsLimitPerDay,
    itemsLimitPerDayDefault,
    selectedBundle
  } = useCreatePromotionContext();
  const [promotionImportanceText, setPromotionImportanceText] = useState("");

  const classes = useStyles();

  useEffect(() => {
    const fetchPromotionText = async () => {
      const text = await promotionAutoApply();
      setPromotionImportanceText(text);
    };
    fetchPromotionText();
  }, [checkedAutoApply]);

  const promotionAutoApply = () => {
    switch (selectedButton) {
      case 2:
        return "2 (ค่อนข้างสำคัญ)";
      case 3:
        return "3 (สำคัญปานกลาง)";
      case 4:
        return "4 (สำคัญค่อนข้างต่ำ)";
      case 5:
        return "5 (ไม่สำคัญ)";
      default:
        return "1 (สำคัญที่สุด)";
    }
  };

  const formatThaiDate = (dateString) => {
    return moment(dateString)
      .locale("th")
      .tz("Asia/Bangkok") // ตั้งโซนเวลาเป็นไทย
      .add(543, "years") // เพิ่ม 543 ปีให้เป็น พ.ศ.
      .format("DD MMM YYYY"); // กำหนดฟอร์แมตวันที่ เช่น 01 ม.ค. 2567
  };

  const PurchaseType = ({ imageSrc, altText, label }) => {
    return (
      <div className="text-center mx-3">
        <img
          src={imageSrc}
          alt={altText}
          style={{ width: "46px", margin: "8px" }}
        />
        <p className="m-0">{label}</p>
      </div>
    );
  };

  return (
    <div
      className="container p-6 border rounded"
      style={{
        backgroundColor: "#FFFFFF",
        fontFamily: "Sarabun",
        fontSize: "16px",
      }}
    >
      <div className="d-flex justify-center align-items-center p-4 rounded">
        <h5 style={{ fontWeight: 600 }}>SUMMARY</h5>
      </div>

      <hr
        style={{ width: "100%", border: "1px solid #EBEDF3", margin: "20px 0" }}
      />

      <div className="d-flex flex-column align-items-center ">
        {stateItem_image_path.previewItem_image_path ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: "240px",
              height: "200px",
              cursor: "pointer",
            }}
          >
            <img
              src={stateItem_image_path.previewItem_image_path}
              alt="Preview"
              style={{
                borderRadius: "8px",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          <div
            className="bg-light d-flex flex-column justify-content-center align-items-center"
            style={{
              border: "1px dashed #ccc",
              borderWidth: "2px",
              borderRadius: "8px",
              padding: "16px",
              textAlign: "center",
              width: "240px",
              height: "240px",
              cursor: "pointer",
            }}
          >
            <Photo
              style={{
                fontSize: 70,
                color: "#aaa",
                paddingTop: "10px",
              }}
            />
            <p>ไม่มีรูปภาพ</p>
          </div>
        )}

        <h1
          className="mt-6"
          style={{
            fontWeight: 600,
            fontSize: "18px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.3rem",
          }}
        >
          {promotionName}
        </h1>
        <p
          style={{
            fontFamily: "Sarabun",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "20.8px",
            textAlign: "center",
            color: "#71717A",
            borderRadius: "4px",
          }}
        >
          {promotionDescription}
        </p>
        <p>โปรโมชั่นสามารถใช้ได้: {checkedRedemption == false ? `ไม่จำกัดครั้ง` : `${redemptionDefault} ครั้ง`}</p>
        <p>{checkedItemsLimitPerDay == true && (`ในหนึ่งวัน สามารถใช้โปรโมชั่นได้: ${itemsLimitPerDayDefault} ครั้ง`)}</p>
        <div className="promotion-view-tags">
          <span className={`badge mx-2 ${classes.customBadge}`}>
            {checkedAutoApply
              ? `ความสำคัญของโปรโมชั่น ${promotionImportanceText}`
              : "โปรโมชั่นจะไม่ถูกใช้งานโดยอัตโนมัติ"}
          </span>
          <span className={`badge mx-2 ${classes.customBadge}`}>
            {couponType ? "ลูกค้ามองเห็นโปรโมชั่นนี้" : "พนักงานต้องกดใช้โปรโมชั่นให้ลูกค้า"}
          </span>
          <span className={`badge mx-2 ${classes.customBadge}`}>
            {`${formatThaiDate(startDate2)} - ${formatThaiDate(expiryDate2)}`}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {timeType2 ? `เวลาใช้งาน: ${timeType2}` : null}
          </span>

          <span className={`badge mx-2 ${classes.customBadge}`}>
            {dayType2 ? `วันที่สามารถใช้งานได้: ${dayType2}` : null}
          </span>
          
          <span className={`badge mx-2 ${classes.customBadge}`}>
            {checkedIncludeOption ? "รวมตัวเลือก" : "ไม่รวมตัวเลือก"}
          </span>
        </div>

        <div className="d-flex justify-content-center my-3">
          {!!purchaseTypes.isDineIn && (
            <PurchaseType
              imageSrc="/media/mockup/Step5/ทานที่ร้าน.jpg"
              altText="Dine In"
              label="ทานที่ร้าน"
            />
          )}
          {!!purchaseTypes.isPickUp && (
            <PurchaseType
              imageSrc="/media/mockup/Step5/รับที่ร้าน.jpg"
              altText="Pick Up"
              label="รับที่ร้าน"
            />
          )}
          {!!purchaseTypes.isTakeAway && (
            <PurchaseType
              imageSrc="/media/mockup/Step5/บริการจัดส่ง.jpg"
              altText="Delivery"
              label="บริการจัดส่ง"
            />
          )}
        </div>

        <hr
          style={{
            border: "none",
            borderTop: "1px dashed #DADEEA",
            width: "100%",
            margin: "1rem 0",
          }}
        />

        <div className="container text-center">
          {/* Discount and Free Item Sections */}

          <>
           <strong className="mt-8 mb-3 d-block text-center" 
           style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "21px",
                textAlign: "left",
              }}
              >
                {promotionTypeId === 14 && `เมื่อซื้อสินค้า ${orderQuantity} รายการ จากในรายการเหล่านี้`}
                {(promotionTypeId === 1 && discountTypeId === 3 && discountConditionId !== 12) && `รับส่วนลด ${targetFixedPrice} บาท สำหรับรายการเหล่านี้`}
                {(promotionTypeId === 1 && discountTypeId === 3 && discountConditionId === 12) && `รับส่วนลด ${targetFixedPrice} บาท`}
                {(promotionTypeId === 2 && discountTypeId === 3) && `รับส่วนลด ${targetFixedPrice} บาท`}
                {(promotionTypeId === 1 && discountTypeId === 4 && discountConditionId !== 12) && `รับส่วนลด ${targetPercent} % สำหรับรายการเหล่านี้`}
                {(promotionTypeId === 1 && discountTypeId === 4 && discountConditionId === 12) && `รับส่วนลด ${targetPercent} % `}
                {(promotionTypeId === 2 && discountTypeId === 4) && `รับส่วนลด ${targetPercent} % `}
              <span
                className="mt-8 mb-3 d-inline"
                style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "21px",
                      textAlign: "left",
                    }}
                >
                  {discountConditionId === 12  && ` เมื่อมียอดซื้อขั้นต่ำ ${orderQuantity} บาท`}
                 {selectedBundle === 8  && ` เมื่อซื้อสินค้าภายในรายการเดียวกันครบ ${orderQuantity} รายการ`}
                  {selectedBundle === 9  &&  ` เมื่อซื้อสินค้าภายในรายการครบ ${orderQuantity} รายการ แบบคละกัน (ไม่ซ้ำรายการเดิม)`}
              </span>
           </strong>
           
            {(promotionTypeId === 14 || promotionTypeId === 2) && (
              <div
                // className="w-100 d-flex flex-column align-items-start justify-content-center"
                className="w-100"
                style={{ marginBottom: "10px" }}
              >
                {discountConditionId === 5 && (
                  <>
                    <div
                      className="w-100 d-flex flex-wrap align-items-start justify-content-center"
                      style={{ gap: "10px" }}
                    >
                      {menuItems
                        .filter((item) => selectedOrderItems.includes(item.id))
                        .map((item, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center p-2 rounded"
                            style={{
                              width: "364px",
                              height: "71px",
                              border: "1px solid #DADEEA",
                              borderRadius: "5px",
                              padding: "10px",
                            }}
                          >
                            <img
                              src={
                                item.item_image_path ||
                                "./media/mockup/picture-default.png"
                              }
                              alt={item.item_name}
                              style={{
                                width: "43px",
                                height: "43px",
                                margin: "14px",
                                borderRadius: "8px",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <strong
                                style={{
                                  fontFamily: "Sarabun, sans-serif",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "#3F4254",
                                }}
                              >
                                {item.item_name}
                              </strong>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "13px",
                                  color: "#71717A",
                                  marginTop: "5px",
                                }}
                              >
                                {item.price} THB
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
                {discountConditionId === 6 && (
                  <>
                    <div
                      className="w-100 d-flex flex-wrap align-items-start justify-content-center"
                      style={{ gap: "10px" }}
                    >
                      {Object.entries(groupMenuMap)
                        .filter(([key]) => selectedOrderGroups.includes(key)) // ใช้ key เพื่อตรวจสอบ
                        .flatMap(([, items]) => items) // รวม items ทั้งหมดจาก key ที่ตรงกัน
                        .map((item, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center p-2 rounded"
                            style={{
                              width: "364px",
                              height: "71px",
                              border: "1px solid #DADEEA",
                              borderRadius: "5px",
                              padding: "10px",
                            }}
                          >
                            <img
                              src={
                                item.item_image_path ||
                                "./media/mockup/picture-default.png"
                              }
                              alt={item.item_name}
                              style={{
                                width: "43px",
                                height: "43px",
                                margin: "14px",
                                borderRadius: "8px",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <strong
                                style={{
                                  fontFamily: "Sarabun, sans-serif",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "#3F4254",
                                }}
                              >
                                {item.item_name}
                              </strong>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "13px",
                                  color: "#71717A",
                                  marginTop: "5px",
                                }}
                              >
                                {item.price} THB
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            )}
            {promotionTypeId === 1 && (
              <div
                // className="w-100 d-flex flex-column align-items-start justify-content-center"
                className="w-100"
                style={{ marginBottom: "10px" }}
              >
                {discountConditionId === 5 && (
                  <>
                    <div
                      className="w-100 d-flex flex-wrap align-items-start justify-content-center"
                      style={{ gap: "10px" }}
                    >
                      {menuItems
                        .filter((item) => selectedOrderItems.includes(item.id))
                        .map((item, index) => {
                          const discountedPrice3 = Math.max(
                            item.price - targetFixedPrice,
                            0
                          );
                          const discountedPrice4 = Math.max(
                            (item.price * (100 - targetPercent)) / 100,
                            0
                          );

                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center p-2 rounded"
                              style={{
                                width: "364px",
                                height: "71px",
                                border: "1px solid #DADEEA",
                                borderRadius: "5px",
                                padding: "10px",
                              }}
                            >
                              <img
                                src={
                                  item.item_image_path ||
                                  "./media/mockup/picture-default.png"
                                }
                                alt={item.item_name}
                                style={{
                                  width: "43px",
                                  height: "43px",
                                  margin: "14px",
                                  borderRadius: "8px",
                                }}
                              />
                              <div style={{ flex: 1 }}>
                                <strong
                                  style={{
                                    fontFamily: "Sarabun, sans-serif",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    color: "#3F4254",
                                  }}
                                >
                                  {item.item_name}
                                </strong>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "13px",
                                    color: "#71717A",
                                    textDecoration:
                                      discountTypeId === 3 ||
                                      discountTypeId === 4
                                        ? "line-through"
                                        : "none",
                                  }}
                                >
                                  {item.price} THB
                                </p>
                                {discountTypeId === 3 && (
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: 600,
                                      margin: "0",
                                      color: "red",
                                    }}
                                  >
                                    {discountedPrice3.toFixed(2)} THB
                                  </p>
                                )}
                                {discountTypeId === 4 && (
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: 600,
                                      margin: "0",
                                      color: "red",
                                    }}
                                  >
                                    {discountedPrice4.toFixed(2)} THB
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}

                {discountConditionId === 6 && (
                  <div
                    className="w-100 d-flex flex-wrap align-items-start justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    {Object.entries(groupMenuMap)
                      .filter(([key]) => selectedOrderGroups.includes(key))
                      .flatMap(([, items]) => items)
                      .map((item, index) => {
                        const discountedPrice3 = Math.max(
                          item.price - targetFixedPrice,
                          0
                        );
                        const discountedPrice4 = Math.max(
                          (item.price * (100 - targetPercent)) / 100,
                          0
                        );

                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center p-2 rounded"
                            style={{
                              width: "364px",
                              height: "71px",
                              border: "1px solid #DADEEA",
                              borderRadius: "5px",
                              padding: "10px",
                            }}
                          >
                            <img
                              src={
                                item.item_image_path ||
                                "./media/mockup/picture-default.png"
                              }
                              alt={item.item_name}
                              style={{
                                width: "43px",
                                height: "43px",
                                margin: "14px",
                                borderRadius: "8px",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <strong
                                style={{
                                  fontFamily: "Sarabun, sans-serif",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "#3F4254",
                                }}
                              >
                                {item.item_name}
                              </strong>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "13px",
                                  color: "#71717A",
                                  textDecoration:
                                    discountTypeId === 3 || discountTypeId === 4
                                      ? "line-through"
                                      : "none",
                                }}
                              >
                                {item.price} THB
                              </p>
                              {discountTypeId === 3 && (
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    margin: "0",
                                    color: "red",
                                  }}
                                >
                                  {discountedPrice3.toFixed(2)} THB
                                </p>
                              )}
                              {discountTypeId === 4 && (
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    margin: "0",
                                    color: "red",
                                  }}
                                >
                                  {discountedPrice4.toFixed(2)} THB
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
          </>

          {promotionTypeId === 14 && (
            <>
              <strong
                className="mt-9 mb-3 d-block text-center"
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "21px",
                  textAlign: "left",
                }}
              >
                {discountTypeId === 15 &&`รับฟรี ${targetQuantity} รายการ จากในรายการเหล่านี้`}
                {discountTypeId === 3 &&`ลดราคา ${targetFixedPrice} บาท`}
                {discountTypeId === 4 &&`ลดราคา ${targetPercent} %`}

              </strong>

              <div
                // className="w-100 d-flex flex-column align-items-start justify-content-center"
                className="w-100"
                style={{ marginBottom: "10px" }}
              >
                {discountTargetId === 5 && (
                  <>
                    <div
                      className="w-100 d-flex flex-wrap align-items-start justify-content-center"
                      style={{ gap: "10px" }}
                    >
                      {menuItems
                        .filter((item) => selectedTargetItems.includes(item.id))
                        .map((item, index) => {
                          const discountedPrice3 = Math.max(
                            item.price - targetFixedPrice,
                            0
                          );
                          const discountedPrice4 = Math.max(
                            (item.price * (100 - targetPercent)) / 100,
                            0
                          );

                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center p-2 rounded"
                              style={{
                                width: "364px",
                                height: "71px",
                                border: "1px solid #DADEEA",
                                borderRadius: "5px",
                                padding: "10px",
                              }}
                            >
                              <img
                                src={
                                  item.item_image_path ||
                                  "./media/mockup/picture-default.png"
                                }
                                alt={item.item_name}
                                style={{
                                  width: "43px",
                                  height: "43px",
                                  margin: "14px",
                                  borderRadius: "8px",
                                }}
                              />
                              <div style={{ flex: 1 }}>
                                <strong
                                  style={{
                                    fontFamily: "Sarabun, sans-serif",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    color: "#3F4254",
                                  }}
                                >
                                  {item.item_name}
                                </strong>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "13px",
                                    color: "#71717A",
                                    // textDecoration:
                                    //   discountTypeId === 3 ||
                                    //   discountTypeId === 4
                                    //     ? "line-through"
                                    //     : "none",
                                  }}
                                >
                                  {item.price} THB
                                </p>
                                {/* {discountTypeId === 3 && (
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: 600,
                                      margin: "0",
                                      color: "red",
                                    }}
                                  >
                                    {discountedPrice3.toFixed(2)} THB
                                  </p>
                                )}
                                {discountTypeId === 4 && (
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: 600,
                                      margin: "0",
                                      color: "red",
                                    }}
                                  >
                                    {discountedPrice4.toFixed(2)} THB
                                  </p>
                                )} */}
                                 
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}

                {discountTargetId === 6 && (
                  <div
                    className="w-100 d-flex flex-wrap align-items-start justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    {Object.entries(groupMenuMap)
                      .filter(([key]) => selectedTargetGroups.includes(key))
                      .flatMap(([, items]) => items)
                      .map((item, index) => {
                        const discountedPrice3 = Math.max(
                          item.price - targetFixedPrice,
                          0
                        );
                        const discountedPrice4 = Math.max(
                          (item.price * (100 - targetPercent)) / 100,
                          0
                        );

                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center p-2 rounded"
                            style={{
                              width: "364px",
                              height: "71px",
                              border: "1px solid #DADEEA",
                              borderRadius: "5px",
                              padding: "10px",
                            }}
                          >
                            <img
                              src={
                                item.item_image_path ||
                                "./media/mockup/picture-default.png"
                              }
                              alt={item.item_name}
                              style={{
                                width: "43px",
                                height: "43px",
                                margin: "14px",
                                borderRadius: "8px",
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <strong
                                style={{
                                  fontFamily: "Sarabun, sans-serif",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "#3F4254",
                                }}
                              >
                                {item.item_name}
                              </strong>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "13px",
                                  color: "#71717A",
                                  // textDecoration:
                                  //   discountTypeId === 3 || discountTypeId === 4
                                  //     ? "line-through"
                                  //     : "none",
                                }}
                              >
                                {item.price} THB
                              </p>
                              {/* {discountTypeId === 3 && (
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    margin: "0",
                                    color: "red",
                                  }}
                                >
                                  {discountedPrice3.toFixed(2)} THB
                                </p>
                              )}
                              {discountTypeId === 4 && (
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    margin: "0",
                                    color: "red",
                                  }}
                                >
                                  {discountedPrice4.toFixed(2)} THB
                                </p>
                              )} */}
                             
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
