import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
// import { useIntl } from "react-intl";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";

const useStyles = makeStyles({
  radioContainer: {
    border: "1px solid #e0e0e0",
    height: "100%",
    borderRadius: "8px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: "#f0f0f0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "24px",
  },
  labelText: {
    fontSize: "16px",
    margin: 0,
  },
  radioIcon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: "2px solid #b0b0b0", // Gray border when unchecked
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioCheckedIcon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#1a73e8", // Blue color when checked
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioCheckedDot: {
    width: 10,
    height: 10,
    backgroundColor: "#ffffff", // White dot in the center
    borderRadius: "50%",
  },
});

export default function CurrentStep2() {
  const classes = useStyles();
  // const intl = useIntl();

  const {
    eventDiscountType,
    eventDiscountCondition,
    eventDiscountTarget,
    handleDiscountTypeSelect,
    handleDiscountTargetSelect,
    handleDiscountConditionSelect,
    promotionTypeId,
    selectedBundle, 
    setSelectedBundle, 
    // bundleOptions
  } = useCreatePromotionContext();

  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const bundleOptions = [
    {
      id: 8,
      title: "SAME ITEM",
      icon: "./media/icon/samex3.png", 
    },
    {
      id: 9,
      title: "DIFFERENT ITEM",
      icon: "./media/icon/differentx3.png", 
    },
  ];

  const handleConditionChange = (id) => {
    setSelectedCondition(id);
    handleDiscountConditionSelect(id);
  };

  const handleTargetChange = (id) => {
    setSelectedTarget(id);
    handleDiscountTargetSelect(id);
  };

  const handleTypeChange = (id) => {
    setSelectedType(id);
    handleDiscountTypeSelect(id);
  };

  const handleBundleChange = (id) => {
    setSelectedBundle(id); 
    // console.log(`Selected Bundle: ${id}`); 
  };

  return (
    <div
      className="d-flex flex-column"
      style={{
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "Sarabun, sans-serif",
      }}
    >
      <Card className="mw-100">
        <CardHeader
          title="Select Discount Type"
          style={{ fontsize: "18px" }}
          className
        ></CardHeader>
        <CardBody>
              <p>Select Condition By</p>
              <div className="row mb-6">
                {eventDiscountCondition.map((data,index) => (
                  <div className="col-md-4 mb-3" key={data.id || index}>
                    <div
                      className={classes.radioContainer}
                      onClick={() =>
                        handleConditionChange(data.discount_condition_id)
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className={classes.iconContainer}>
                          <img
                            src={data.icon}
                            alt="discount condition"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                        <label className="d-flex flex-column justify-content-center mb-0">
                          <p className={classes.labelText}>{data.title}</p>
                        </label>
                      </div>
                      <Radio
                        checked={
                          selectedCondition === data.discount_condition_id
                        }
                        onChange={() =>
                          handleConditionChange(data.discount_condition_id)
                        }
                        icon={<span className={classes.radioIcon} />}
                        checkedIcon={
                          <span className={classes.radioCheckedIcon}>
                            <span className={classes.radioCheckedDot} />
                          </span>
                        }
                        name="discountCondition"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                ))}
                {promotionTypeId === 1 && (
                  <div className="col-md-4 mb-3">
                    <div
                      className={classes.radioContainer}
                      onClick={() => handleConditionChange(12)}
                    >
                      <div className="d-flex align-items-center">
                        <div className={classes.iconContainer}>
                          <img
                            src="./media/icon/by-billx3.png"
                            alt="discount condition"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                        <label className="d-flex flex-column justify-content-center mb-0">
                          <p className={classes.labelText}>BY BILL</p>
                        </label>
                      </div>
                      <Radio
                        checked={selectedCondition === 12}
                        onChange={() => handleConditionChange(12)}
                        icon={<span className={classes.radioIcon} />}
                        checkedIcon={
                          <span className={classes.radioCheckedIcon}>
                            <span className={classes.radioCheckedDot} />
                          </span>
                        }
                        name="discountCondition"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                )}
              </div>
          {promotionTypeId === 2 && (
            <>
              <p>Select Discount Bundle By</p>
              <div className="row mb-6">
                {bundleOptions.map((data) => (
                  <div className="col-md-4 mb-3" key={data.id}>
                    <div
                      className={classes.radioContainer}
                      onClick={() =>
                        handleBundleChange(data.id)
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className={classes.iconContainer}>
                          <img
                            src={data.icon}
                            alt="bundle"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                        <label className="d-flex flex-column justify-content-center mb-0">
                          <p className={classes.labelText}>{data.title}</p>
                        </label>
                      </div>
                      <Radio
                        checked={selectedBundle === data.id}
                        onChange={() => handleBundleChange(data.id)}

                        icon={<span className={classes.radioIcon} />}
                        checkedIcon={
                          <span className={classes.radioCheckedIcon}>
                            <span className={classes.radioCheckedDot} />
                          </span>
                        }
                        name="discountTarget"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {promotionTypeId === 14 && (
            <>
              <p>Select Discount Target By</p>
              <div className="row mb-6">
                {eventDiscountTarget.map((data) => (
                  <div className="col-md-4 mb-3" key={data.id}>
                    <div
                      className={classes.radioContainer}
                      onClick={() =>
                        handleTargetChange(data.discount_condition_id)
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className={classes.iconContainer}>
                          <img
                            src={data.icon}
                            alt="discount target"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                        <label className="d-flex flex-column justify-content-center mb-0">
                          <p className={classes.labelText}>{data.title}</p>
                        </label>
                      </div>
                      <Radio
                        checked={selectedTarget === data.discount_condition_id}
                        onChange={() =>
                          handleTargetChange(data.discount_condition_id)
                        }
                        icon={<span className={classes.radioIcon} />}
                        checkedIcon={
                          <span className={classes.radioCheckedIcon}>
                            <span className={classes.radioCheckedDot} />
                          </span>
                        }
                        name="discountTarget"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <p>Select Discount Type By</p>
          <div className="row">
            {(promotionTypeId === 1 || promotionTypeId === 2) &&
              eventDiscountType
                .filter((item) => item.discount_type_id !== 15)
                .map((data,index) => (
                  <div className="col-md-4 mb-3" key={data.id || index}>
                    <div
                      className={classes.radioContainer}
                      onClick={() => handleTypeChange(data.discount_type_id)}
                    >
                      <div className="d-flex align-items-center">
                        <div className={classes.iconContainer}>
                          <img
                            src={data.icon}
                            alt="discount type"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                        <label className="d-flex flex-column justify-content-center mb-0">
                          <p className={classes.labelText}>{data.title}</p>
                        </label>
                      </div>
                      <Radio
                        checked={selectedType === data.discount_type_id}
                        onChange={() => handleTypeChange(data.discount_type_id)}
                        icon={<span className={classes.radioIcon} />}
                        checkedIcon={
                          <span className={classes.radioCheckedIcon}>
                            <span className={classes.radioCheckedDot} />
                          </span>
                        }
                        name="discountType"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  </div>
                ))}
            {promotionTypeId === 14 &&
              eventDiscountType.map((data) => (
                <div className="col-md-4 mb-3" key={data.id}>
                  <div
                    className={classes.radioContainer}
                    onClick={() => handleTypeChange(data.discount_type_id)}
                  >
                    <div className="d-flex align-items-center">
                      <div className={classes.iconContainer}>
                        <img
                          src={data.icon}
                          alt="discount type"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                      <label className="d-flex flex-column justify-content-center mb-0">
                        <p className={classes.labelText}>{data.title}</p>
                      </label>
                    </div>
                    <Radio
                      checked={selectedType === data.discount_type_id}
                      onChange={() => handleTypeChange(data.discount_type_id)}
                      icon={<span className={classes.radioIcon} />}
                      checkedIcon={
                        <span className={classes.radioCheckedIcon}>
                          <span className={classes.radioCheckedDot} />
                        </span>
                      }
                      name="discountType"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
