import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import Modal from "@material-ui/core/Modal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import NotFound from "./NotFound";
import CloseIcon from "@material-ui/icons/Close";

export default function PromotionList({
  handleClose,
  intl,
  promotion,
  search,
  setSearch,
  columns,
  pagination,
  handleSearch,
  openImage,
  setOpenImage,
  imgSelect,
  setImgSelect,
  Card,
  CardHeader,
  CardBody,
  CardHeaderToolbar,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
}) 
{
  useEffect(() => {
    console.log("promotion >>>> ", promotion);
  }, [promotion]);

  return (
    <Card className="w-100 h-100">
      <CardHeader title="Promotion">
        <CardHeaderToolbar>
          <div className="mr-3" style={{ position: "relative", width: "auto" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              style={{ width: "100%", paddingRight: "30px" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            {search && (
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  color: "#aaa",
                }}
                onClick={() => {
                  const newSearch = "";
                  setSearch(newSearch);
                }}
              >
                <CloseIcon
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                />
              </button>
            )}
          </div>
          <div className="mr-6">
            <button
              type="button"
              id="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleSearch()}
            >
              Search
            </button>
          </div>

          <Link to="/CreatePromotionNew">
            <button type="button" className="btn btn-primary">
              {intl.formatMessage({
                id: "113002",
                defaultMessage: "+ Create",
              })}

              {/* "113002" : "Create", */}
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            keyField="id"
            pagination={pagination}
            data={promotion === undefined ? [] : promotion}
            columns={columns}
          ></BootstrapTable>

        {/* {promotion.length ? (
          <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            keyField="id"
            pagination={pagination}
            data={promotion === undefined ? [] : promotion}
            columns={columns}
          ></BootstrapTable>
        ) : (
          <NotFound
            isFetch={false}
            title="No promotion"
            description="Create a promotional list to promote sales."
          />
        )} */}

        {/* <PleaseWaitMessage entities={promotion} />
          <NoRecordsFoundMessage entities={promotion} /> */}
      </CardBody>

      <Modal
        open={openImage}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                    <div className="modal-body">
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "500px",
                          maxHeight: "500px",
                        }}
                        src={imgSelect}
                      />
                    </div>
                  </TransformComponent>
                  <div className="modal-footer">
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomIn}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomOut}
                      >
                        -
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>
    </Card>
  );
}
