import React from "react";
import { CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import NotFound from "./NotFound";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import { useIntl } from "react-intl";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext.js";
import { OrderButtonModal } from "./OrderButtonModal.js";
import { TargetButtonModal } from "./TargetButtonModal.js";
import CloseIcon from "@material-ui/icons/Close";

export default function CurrentStep3() {
  const intl = useIntl();
  const {
    orderQuantity,
    targetQuantity,
    menuItems,
    menuGroup,
    handleOrderQuantityChange,
    handleTargetQuantityChange,
    handleRemoveOrderItems,
    handleRemoveOrderGroups,
    handleRemoveTargetItems,
    handleRemoveTargetGroups,
    discountConditionId,
    discountTargetId,
    selectedOrderItems,
    selectedTargetItems,
    selectedOrderGroups,
    selectedTargetGroups,
    discountTypeId,
    targetFixedPrice,
    targetPercent,
    handleTargetFixedPriceChange,
    handleTargetPercentChange,
    promotionTypeId, selectedBundle
  } = useCreatePromotionContext();

  const renderInputSection = (label, value, onChange, unit) => (
    <p
      className="m-0 py-6 "
      style={{
        borderBottom: "1px dashed #DADEEA",
        borderWidth: "2px",
        fontSize: "14px",
      }}
    >
      {label}
      <input
        type="text"
        className="form-control d-inline-block mx-3"
        value={value}
        onChange={onChange}
        onKeyDown={(event) => {
          const allowedKeys = ["Backspace", "Delete"];
          const isNumberKey = event.key >= "0" && event.key <= "9";
          if (!isNumberKey && !allowedKeys.includes(event.key)) {
            event.preventDefault();
          }
        }}
        style={{ width: "80px", textAlign: "center" }}
      />{" "}
      {unit}
    </p>
  );
  const renderInputSectionBottom = (label, value, onChange, unit) => (
    <p
      className="m-0 py-6"
      style={{
        fontSize: "14px",
      }}
    >
      {label}
      <input
        type="text"
        className="form-control d-inline-block mx-3"
        value={value}
        onChange={onChange}
        onKeyDown={(event) => {
          const allowedKeys = ["Backspace", "Delete"];
          const isNumberKey = event.key >= "0" && event.key <= "9";
          if (!isNumberKey && !allowedKeys.includes(event.key)) {
            event.preventDefault();
          }
        }}
        style={{ width: "80px", textAlign: "center" }}
      />{" "}
      {unit}
    </p>
  );

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="row w-100 justify-content-center">
          {/* Left Column : Condition Discount*/}

          <div className="col-12 col-md-6">
            <Card className="w-100">
              <CardHeader
                // title={intl.formatMessage({
                //   id: "OrderToBuy",
                //   defaultMessage: "เมนูที่สั่งซื้อ",
                // })}
                title={
                  promotionTypeId === 14
                    ? "เมนูที่สั่งซื้อ"
                    : "เลือกรายการส่วนลดตาม"
                }
              >
                <CardHeaderToolbar>
                  <OrderButtonModal />
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody className="py-0">
                <div>
                  <>
                    {/* {discountTypeId === 15 && */}
                    {(promotionTypeId === 14) &&
                      renderInputSection(
                        "เมื่อซื้อเมนู",
                        orderQuantity,
                        handleOrderQuantityChange,
                        "ของรายการเหล่านี้"
                      )}
                    {(discountTypeId === 3 && promotionTypeId !== 14) &&
                      renderInputSection(
                        "ลดราคา",
                        targetFixedPrice,
                        handleTargetFixedPriceChange,
                        "บาท"
                      )}
                    {(discountTypeId === 4 && promotionTypeId !== 14) &&
                      renderInputSection(
                        "ลดราคา",
                        targetPercent,
                        handleTargetPercentChange,
                        "%"
                      )}
                  </>
                  {(discountConditionId === 12 ) &&
                    renderInputSectionBottom(
                      "ยอดซื้อขั้นต่ำ",
                      orderQuantity,
                      handleOrderQuantityChange,
                      "บาท"
                    )}
                  {(promotionTypeId === 2) &&
                    renderInputSection(
                      "จำนวนที่ต้องการ",
                      orderQuantity,
                      handleOrderQuantityChange,
                      "รายการ"
                    )}
                    

                  {/* Render สำหรับ discountConditionId === 5 */}
                  <div className={`${selectedOrderItems.length && discountConditionId === 5 ? "pt-3 pb-6": "p-0"}`}>
                  {discountConditionId === 5 &&
                    (menuItems.filter((item) =>
                      selectedOrderItems.includes(item.id)
                    ).length > 0 ? (
                      menuItems
                        .filter((item) => selectedOrderItems.includes(item.id))
                        .map((item) => (
                          <div className="container pt-3 px-0 m-0" key={item.id}>
                            <div
                              className="row m-0 p-3 d-flex align-items-stretch"
                              style={{
                                border: "1px solid #E0E0E0",
                                borderRadius: "8px",
                                marginBottom: "8px",
                                height: "70px",
                              }}
                            >
                              {/* Left side: Image and Details */}
                              <div className="col-10 d-flex align-items-center h-100">
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "8px",
                                    marginRight: "16px",
                                  }}
                                  src={
                                    item.item_image_path
                                      ? item.item_image_path
                                      : "/media/icon/picture-default.png"
                                  }
                                  alt={item.item_name}
                                />
                                <div>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      margin: "0",
                                    }}
                                  >
                                    {item.item_name}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#757575",
                                      margin: "0",
                                    }}
                                  >
                                    {item.price} THB
                                  </p>
                                </div>
                              </div>

                              {/* Right side: Close button */}
                              <div
                                className="col-2 d-flex justify-content-end align-items-center h-100"
                                style={{ height: "100%" }}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveOrderItems(item.id)
                                  }
                                  style={{
                                    backgroundColor: "#000",
                                    color: "#fff",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <CloseIcon
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "#fff",
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="d-flex py-20">
                        <NotFound
                          isFetch={false}
                          title="ไม่มีรายการ"
                          description="เพิ่มสินค้าเพื่อสร้างโปรโมชั่น"
                        />
                      </div>
                    ))
                  }
                  </div>

                  {/* Render สำหรับ discountConditionId === 6 */}
                  <div className={`${selectedOrderGroups.length && discountConditionId === 6 ? "py-3": "p-0"}`}>
                  {discountConditionId === 6 &&
                    (menuGroup.filter((group) =>
                      selectedOrderGroups.includes(group.value.toString())
                    ).length > 0 ? (
                      menuGroup
                        .filter((group) =>
                          selectedOrderGroups.includes(group.value.toString())
                        )
                        .map((group) => (
                          <div
                            className="container pt-3 px-0 m-0"
                            key={group.value}
                          >
                            <div
                              className="row m-0 p-3 d-flex align-items-stretch"
                              style={{
                                border: "1px solid #E0E0E0",
                                borderRadius: "8px",
                                marginBottom: "8px",
                                height: "70px",
                              }}
                            >
                              {/* Left side: Group name */}
                              <div
                                className="col-10 d-flex align-items-center"
                                style={{ height: "100%" }}
                              >
                                <div>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      margin: "0",
                                    }}
                                  >
                                    {group.label}
                                  </p>
                                </div>
                              </div>

                              {/* Right side: Close button */}
                              <div
                                className="col-2 d-flex justify-content-end align-items-center"
                                style={{ height: "100%" }}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveOrderGroups(group.value)
                                  }
                                  style={{
                                    backgroundColor: "#000",
                                    color: "#fff",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <CloseIcon
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "#fff",
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="d-flex py-20">
                        <NotFound
                          isFetch={false}
                          title="ไม่มีรายการ"
                          description="เพิ่มกลุ่มสินค้าเพื่อสร้างโปรโมชั่น"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          {/* Right Column : Target Discount */}
          {promotionTypeId === 14 && (
            <div className="col-12 col-md-6">
              <Card className="w-100">
                <CardHeader
                  title={intl.formatMessage({
                    id: "OrderToGet",
                    defaultMessage: "เมนูที่จะได้รับ",
                  })}
                >
                  <CardHeaderToolbar>
                    <TargetButtonModal />
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="py-0">
                  <div>
                    {discountTypeId === 15 && renderInputSection(
                      "จะได้รับ",
                      targetQuantity,
                      handleTargetQuantityChange,
                      "ของรายการเหล่านี้"
                    )}
                    {discountTypeId === 3 && renderInputSection(
                        "ลดราคา",
                        targetFixedPrice,
                        handleTargetFixedPriceChange,
                        "บาท"
                      )}
                       {discountTypeId === 4 &&
                      renderInputSection(
                        "ลดราคา",
                        targetPercent,
                        handleTargetPercentChange,
                        "%"
                      )}
                    {/* Render สำหรับ discountTargetId === 5 */}
                     <div className={`${selectedOrderItems.length && discountTargetId === 5 ? "pt-3 pb-6": "p-0"}`}>
                    {discountTargetId === 5 &&
                      (menuItems.filter((item) =>
                        selectedTargetItems.includes(item.id)
                      ).length > 0 ? (
                        menuItems
                          .filter((item) =>
                            selectedTargetItems.includes(item.id)
                          )
                          .map((item) => (
                            <div className="container pt-3 px-0 m-0" key={item.id}>
                              <div
                                className="row m-0 p-3 d-flex align-items-stretch"
                                style={{
                                  border: "1px solid #E0E0E0",
                                  borderRadius: "8px",
                                  marginBottom: "8px",
                                  height: "70px",
                                }}
                              >
                                {/* Left side: Image and Details */}
                                <div className="col-10 d-flex align-items-center h-100">
                                  <img
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "8px",
                                      marginRight: "16px",
                                    }}
                                    src={
                                      item.item_image_path
                                        ? item.item_image_path
                                        : "/media/icon/picture-default.png"
                                    }
                                    alt={item.item_name}
                                  />
                                  <div>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        margin: "0",
                                      }}
                                    >
                                      {item.item_name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#757575",
                                        margin: "0",
                                      }}
                                    >
                                      {item.price} THB
                                    </p>
                                  </div>
                                </div>

                                {/* Right side: Close button */}
                                <div className="col-2 d-flex justify-content-end align-items-center h-100">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveTargetItems(item.id)
                                    }
                                    style={{
                                      backgroundColor: "#000",
                                      color: "#fff",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <CloseIcon
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="d-flex py-20">
                          <NotFound
                            isFetch={false}
                            title="ไม่มีรายการ"
                            description="เพิ่มสินค้าเป้าหมายเพื่อสร้างโปรโมชั่น"
                          />
                        </div>
                      ))}
                     </div>

                    {/* Render สำหรับ discountTargetId === 6 */}
                    <div className={`${selectedOrderGroups.length && discountTargetId === 6 ? "py-3": "p-0"}`}>
                    {discountTargetId === 6 &&
                      (menuGroup.filter((group) =>
                        selectedTargetGroups.includes(group.value.toString())
                      ).length > 0 ? (
                        menuGroup
                          .filter((group) =>
                            selectedTargetGroups.includes(
                              group.value.toString()
                            )
                          )
                          .map((group) => (
                            <div
                              className="container pt-3 px-0 m-0"
                              key={group.value}
                            >
                              <div
                                className="row m-0 p-3 d-flex align-items-stretch"
                                style={{
                                  border: "1px solid #E0E0E0",
                                  borderRadius: "8px",
                                  marginBottom: "8px",
                                  height: "70px",
                                }}
                              >
                                {/* Left side: Group name */}
                                <div className="col-10 d-flex align-items-center h-100">
                                  <div>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        margin: "0",
                                      }}
                                    >
                                      {group.label}
                                    </p>
                                  </div>
                                </div>

                                {/* Right side: Close button */}
                                <div className="col-2 d-flex justify-content-end align-items-center h-100">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveTargetGroups(group.value)
                                    }
                                    style={{
                                      backgroundColor: "#000",
                                      color: "#fff",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <CloseIcon
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "#fff",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="d-flex py-20">
                          <NotFound
                            isFetch={false}
                            title="ไม่มีรายการ"
                            description="เพิ่มกลุ่มสินค้าเป้าหมายเพื่อสร้างโปรโมชั่น"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
