import React from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import { Button, Spinner } from "react-bootstrap";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext.js";
import "react-pagination-library/build/css/index.css";
import "../../component.css";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CurrentStep1 from "../promotion/CurrentStep1.js";
import CurrentStep2 from "../promotion/CurrentStep2.js";
import CurrentStep3 from "../promotion/CurrentStep3.js";
import CurrentStep4 from "../promotion/CurrentStep4.js";
import CurrentStep5 from "../promotion/CurrentStep5.js";

export const CreatePromotionNew = () => {
  const subheader = useSubheader();
  subheader.setTitle("Promotion");

  const {
    currentStep,
    activeStep,
    handleNextStep,
    handleBackStep,
    promotionTypeId,
    discountConditionId,
    discountTargetId,
    discountTypeId,
    selectedOrderItems,
    selectedOrderGroups,
    selectedTargetItems,
    selectedTargetGroups,
    promotionName,
    promotionDescription,
    couponType,
    purchaseTypes,
    startDate2,
    expiryDate2,
    dayType2,
    timeType2,
    handleSubmit,
    selectedBundle,
    isLoading,
    isLoadingPage,
    orderQuantity,
    targetQuantity,
    targetFixedPrice,
    targetPercent
  } = useCreatePromotionContext();

  // const lang = localStorage.getItem("Language");

  const steps = [
    "PROMO TYPE",
    "DISCOUNT TYPE",
    "SET ITEMS",
    "SETTING",
    "SUMMARY",
  ];

  const StepNavigation = ({
    onBack,
    onNext,
    isNextDisabled,
    isNextPrimary,
    isLoading,
    nextText = "Next",
  }) => {
    return (
      <div className="d-flex justify-content-center mt-3" style={{fontFamily: "Sarabun, sans-serif",}}>
        <Button
          variant="outline-primary"
          onClick={onBack}
          style={{
            marginRight: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          variant={isNextPrimary ? "primary" : "secondary"}
          onClick={onNext}
          disabled={isNextDisabled || isLoading}
          style={{
            paddingRight: "24px",
            paddingLeft: "24px",
            cursor: isNextDisabled || isLoading ? "not-allowed" : "pointer",
          }}
        > Next
          {isLoading && <span className="ml-3 spinner spinner-white"></span>}
        </Button>
      </div>
    );
  };

  return (
    <>
      {isLoadingPage ? (
        <>
          <div className="d-flex justify-content-center align-items-center top-0 left-0 w-100 h-100 bg-white bg-opacity-75">
            <div className="d-flex align-items-center">
              <div className="mr-2 text-muted">Loading...</div>
              <div className="spinner spinner-primary mr-10"></div>
            </div>
          </div>
        </>
      ) : (
        <>
            <Box style={{ width: "100%" }}>
              <Stepper
                style={{
                  backgroundColor: "#EEF0F8",
                }}
                activeStep={activeStep}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {/* <div className="d-flex justify-content-center"> */}
            {currentStep === 1 && <CurrentStep1 />}
            {currentStep === 2 && <CurrentStep2 />}
            {currentStep === 3 && <CurrentStep3 />}
            {currentStep === 4 && <CurrentStep4 />}
            {currentStep === 5 && <CurrentStep5 />}

            {currentStep === 1 && (
              <StepNavigation
                onBack={handleBackStep}
                onNext={handleNextStep}
                isNextDisabled={!promotionTypeId}
                isNextPrimary={!!promotionTypeId}
              />
            )}
            {currentStep === 2 && (
              <StepNavigation
                onBack={handleBackStep}
                onNext={handleNextStep}
                isNextDisabled={
                  !(
                    (promotionTypeId === 1 && discountConditionId && discountTypeId) ||
                    (promotionTypeId === 2 && discountConditionId && selectedBundle && discountTypeId) ||
                    (promotionTypeId === 14 && discountConditionId && discountTargetId && discountTypeId)
                  )
                }
                isNextPrimary={
                  !!(
                    (promotionTypeId === 1 && discountConditionId && discountTypeId) ||
                    (promotionTypeId === 2 && discountConditionId && selectedBundle && discountTypeId) ||
                    (promotionTypeId === 14 && discountConditionId && discountTargetId && discountTypeId)
                  )
                }
              />
            )}
            {currentStep === 3 && (
              <StepNavigation
                onBack={handleBackStep}
                onNext={handleNextStep}
                isNextDisabled={
                  !(
                    ((promotionTypeId === 14 && discountTypeId === 3 && (selectedOrderItems.length || selectedOrderGroups.length) && (selectedTargetItems.length || selectedTargetGroups.length) && orderQuantity && targetFixedPrice))
                    ||
                    ((promotionTypeId === 14 && discountTypeId === 4 && (selectedOrderItems.length || selectedOrderGroups.length) && (selectedTargetItems.length || selectedTargetGroups.length) && orderQuantity && targetPercent))
                    ||
                    ((promotionTypeId === 14 && discountTypeId === 15 && (selectedOrderItems.length || selectedOrderGroups.length) && (selectedTargetItems.length || selectedTargetGroups.length) && orderQuantity && targetQuantity))
                    ||
                    (promotionTypeId === 1 && discountTypeId === 3 && targetFixedPrice && (
                      (selectedOrderGroups.length || selectedOrderItems.length) ||
                      (discountConditionId === 12 && orderQuantity)
                    ))
                    ||
                    (promotionTypeId === 1 && discountTypeId === 4 && targetPercent && (
                      (selectedOrderGroups.length || selectedOrderItems.length) ||
                      (discountConditionId === 12 && orderQuantity)
                    ))
                    ||
                    (promotionTypeId === 2 && (selectedOrderItems.length || selectedOrderGroups.length) && (targetFixedPrice || targetPercent) && orderQuantity)
                  )
                }
                isNextPrimary={
                  (
                    ((promotionTypeId === 14 && discountTypeId === 3 && (selectedOrderItems.length || selectedOrderGroups.length) && (selectedTargetItems.length || selectedTargetGroups.length) && orderQuantity && targetFixedPrice))
                    ||
                    ((promotionTypeId === 14 && discountTypeId === 4 && (selectedOrderItems.length || selectedOrderGroups.length) && (selectedTargetItems.length || selectedTargetGroups.length) && orderQuantity && targetPercent))
                    ||
                    ((promotionTypeId === 14 && discountTypeId === 15 && (selectedOrderItems.length || selectedOrderGroups.length) && (selectedTargetItems.length || selectedTargetGroups.length) && orderQuantity && targetQuantity))
                    ||
                    (promotionTypeId === 1 && discountTypeId === 3 && targetFixedPrice && (
                      (selectedOrderGroups.length || selectedOrderItems.length) ||
                      (discountConditionId === 12 && orderQuantity)
                    ))
                    ||
                    (promotionTypeId === 1 && discountTypeId === 4 && targetPercent && (
                      (selectedOrderGroups.length || selectedOrderItems.length) ||
                      (discountConditionId === 12 && orderQuantity)
                    ))
                    ||
                    (promotionTypeId === 2 && (selectedOrderItems.length || selectedOrderGroups.length) && (targetFixedPrice || targetPercent) && orderQuantity)
                  )
                }
              />
            )}
            {currentStep === 4 && (
              <StepNavigation
                onBack={handleBackStep}
                onNext={handleNextStep}
                isNextDisabled={
                  !(promotionName && promotionDescription && couponType &&
                    (purchaseTypes.isDineIn || purchaseTypes.isPickUp || purchaseTypes.isTakeAway) &&
                    startDate2 && expiryDate2 && dayType2 && timeType2)
                }
                isNextPrimary={
                  (promotionName && promotionDescription && couponType &&
                    (purchaseTypes.isDineIn || purchaseTypes.isPickUp || purchaseTypes.isTakeAway) &&
                    startDate2 && expiryDate2 && dayType2 && timeType2)
                }
              />
            )}
            {currentStep === 5 && (
              <StepNavigation
                onBack={handleBackStep}
                onNext={handleSubmit}
                isNextDisabled={false}
                isNextPrimary={true}
                isLoading={isLoading}
                nextText="Submit"
              />
            )}
          </>
      )}
    </>
  );
};
