import React from "react";
import { Card } from "react-bootstrap";
import { AddPhotoAlternate } from "@material-ui/icons";
import { Step4Time } from "./CurrentStep4DateTime.js";
import { Step4Option } from "./CurrentStep4Option.js";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext.js";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  radioContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "12px",
  },
  labelText: {
    fontSize: "16px",
    margin: 0,
  },
  radioIcon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: "2px solid #b0b0b0", // Gray border when unchecked
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioCheckedIcon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "#1a73e8", // Blue color when checked
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioCheckedDot: {
    width: 10,
    height: 10,
    backgroundColor: "#ffffff", // White dot in the center
    borderRadius: "50%",
  },
  customCheckbox: {
    width: "50px",
    height: "50px",
    padding: 0, // Remove default padding
    "& .MuiSvgIcon-root": {
      width: "25px", // Size of the checkbox icon
      height: "25px", // Size of the checkbox icon
    },
    "& .MuiIconButton-root": {
      borderRadius: "7px", // Border radius for the checkbox icon when unchecked
      border: "1px solid #e0e0e0", // Gray border for unchecked state
    },
  },
});

export default function CurrentStep4() {
  const classes = useStyles();

  const {
    orderQuantity,
    targetQuantity,
    promotionName,
    setPromotionName,
    promotionDescription,
    setPromotionDescription,
    menuItems,
    selectedOrderItems,
    selectedTargetItems,
    groupMenuMap,
    selectedOrderGroups,
    selectedTargetGroups,
    couponType,
    setCouponType,
    purchaseTypes,
    setPurchaseTypes,
    purchaseOptions,
    couponOptions,
    stateItem_image_path,
    setStateItem_image_path,
    alertError,
    discountConditionId,
    discountTargetId,
    promotionTypeId,
    discountTypeId,
    targetFixedPrice,
    targetPercent,
    selectedBundle
  } = useCreatePromotionContext();

  const handleChangeImage = async (e) => {
    // const files = e.target.files;

    // if (e.target.files.length) {
    //   const file = files[0];
    const input = e.target; // เก็บตัวอ้างอิงถึง input element
    if (input.files.length) {
      const file = input.files[0];

      // Check if the file size exceeds 2 MB
      if (file.size > 2 * 1024 * 1024) {
        const resizedFile = await resizeImage(file, 1500, 2 * 1024 * 1024);
        if (!resizedFile) {
          alertError("Unable to process the file.");
          return;
        }

        const previewURL = URL.createObjectURL(resizedFile);
        setStateItem_image_path({
          previewItem_image_path: previewURL,
          rawItem_image_path: resizedFile,
        });
      } else {
        // If size is acceptable, check dimensions
        const dimensions = await getImageDimensions(file);
        if (dimensions.width > 1500) {
          const resizedFile = await resizeImage(file, 1500);
          const previewURL = URL.createObjectURL(resizedFile);

          setStateItem_image_path({
            previewItem_image_path: previewURL,
            rawItem_image_path: resizedFile,
          });
        } else {
          const previewURL = URL.createObjectURL(file);
          setStateItem_image_path({
            previewItem_image_path: previewURL,
            rawItem_image_path: file,
          });
        }
      }

      // Clear the input value to allow re-upload of the same file
      // e.target.value = null;
      input.value = ""; // เคลียร์ค่าหลังจากทำงานเสร็จ
    }
  };

  // Utility function to get image dimensions
  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
        URL.revokeObjectURL(url);
      };

      img.onerror = (err) => {
        reject(err);
        URL.revokeObjectURL(url);
      };

      img.src = url;
    });
  };

  const resizeImage = async (file, maxWidth, maxSize) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        let scaleFactor = maxWidth / img.width;
        let quality = 0.7; // Initial quality
        let currentBlob = null;

        // Function to resize and reduce quality iteratively
        const processImage = () => {
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            (blob) => {
              currentBlob = blob;

              // Check if size is within the limit
              if (maxSize && blob.size > maxSize) {
                if (quality > 0.1) {
                  // Reduce quality further and retry
                  quality -= 0.1;
                  scaleFactor -= 0.1; // Reduce dimensions slightly
                  processImage();
                } else {
                  reject(
                    "Resized file exceeds the maximum size limit even after adjustments."
                  );
                }
              } else {
                resolve(new File([blob], file.name, { type: file.type }));
              }
            },
            file.type,
            quality
          );
        };

        processImage(); // Start processing
      };

      img.onerror = (err) => {
        reject(err);
        URL.revokeObjectURL(url);
      };

      img.src = url;
    });
  };

  const handlePromotionNameChange = (e) => {
    setPromotionName(e.target.value);
  };

  const handlePromotionDescriptionChange = (e) => {
    setPromotionDescription(e.target.value);
  };

  const handleCouponTypeChange = (value) => {
    setCouponType(value);
  };

  const handleCheckboxChange = (key) => {
    setPurchaseTypes((prevTypes) => ({
      ...prevTypes,
      [key]: prevTypes[key] === 0 ? 1 : 0,
    }));
  };

  const DiscountDetail = ({ label, value, unit }) => {
    if (!value) return null;

    return (
      <div className="row px-3 px-md-0 mb-3 mb-md-6">
        <div className="col-6 col-md-3 d-flex align-items-center">
          <strong>{label}</strong>
        </div>
        <div className="col-6 col-md-2">
          <div
            className="bg-light mb-3"
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              textAlign: "center",
            }}
          >
            {value} {unit}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card
        className="p-0"
        style={{
          width: "100%",
          padding: "16px",
          fontSize: "16px",
          // fontFamily: "Sarabun, sans-serif",
        }}
      >
        <Card.Header as="h5" style={{ fontSize: "18px" }}>
          ข้อมูลโปรโมชั่น
        </Card.Header>
        <Card.Body className="p-0">
          <div className="container px-md-10">
            {/* Image Upload Section */}
            <div className="row my-6 ">
              <div className="col-3 d-none d-md-block"></div>
              <div className="col-9 col-md-9 col-12">
                <div className="d-flex flex-column align-items-center align-items-md-start">
                  {stateItem_image_path.previewItem_image_path ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: "240px",
                        height: "200px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={stateItem_image_path.previewItem_image_path}
                        alt="Preview"
                        onClick={() =>
                          document.getElementById("image-preview").click()
                        }
                        style={{
                          borderRadius: "8px",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        document.getElementById("image-preview").click()
                      }
                      className="bg-light d-flex flex-column justify-content-center align-items-center"
                      style={{
                        border: "1px dashed #ccc",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        padding: "16px",
                        textAlign: "center",
                        width: "240px",
                        height: "240px",
                        cursor: "pointer",
                      }}
                    >
                      <AddPhotoAlternate
                        style={{
                          fontSize: 70,
                          color: "#aaa",
                          paddingTop: "10px",
                        }}
                      />
                      <p>เพิ่มรูปภาพ</p>
                    </div>
                  )}

                  <input
                    id="image-preview"
                    type="file"
                    // accept=".jpg,.png"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    onChange={handleChangeImage}
                  />
                </div>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <p className="mt-3" style={{ fontSize: "14px" }}>
                    .jpg .png (ขนาดไฟล์​ไม่เกิน 2 MB)
                  </p>
                </div>
              </div>
            </div>

            {/* Promotion Name */}
            <div className="row ">
              <div className="col-12 col-md-3 pt-3">
                <strong>
                  ชื่อโปรโมชั่น <span className="text-danger">*</span>
                </strong>
              </div>
              <div className="col-12 col-md-6 mt-3" style={{ color: "gray" }}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ชื่อ"
                    value={promotionName}
                    onChange={handlePromotionNameChange}
                    style={{
                      // width: "60%",
                      color: "#495057",
                      borderColor: "#ccc",
                      fontSize: "16px",
                    }}
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                    color: gray; 
                    }
                    `}
                  </style>
                  <label className="mt-3" style={{ fontSize: "14px" }}>
                    ชื่อรายการและคำอธิบายได้รับการแปลเป็น 2 ภาษา
                  </label>
                </div>
              </div>
            </div>
            {/* Promotion Details */}
            <div className="row">
              <div className="col-12 col-md-3 pt-3">
                <strong>
                  รายละเอียด <span className="text-danger">*</span>
                </strong>
              </div>
              <div className="col-12 col-md-6 mt-3" style={{ color: "gray" }}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="รายละเอียด"
                    value={promotionDescription}
                    onChange={handlePromotionDescriptionChange}
                    style={{
                      // width: "60%",
                      color: "#495057",
                      borderColor: "#ccc",
                      fontSize: "16px",
                    }}
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                    color: gray; 
                    }
                    `}
                  </style>
                  <label className="mt-3" style={{ fontSize: "14px" }}>
                    ชื่อรายการและคำอธิบายได้รับการแปลเป็น 2 ภาษา
                  </label>
                </div>
              </div>
            </div>

            {/* Coupon Type */}
            <div className="row mb-6">
              <div className="col-12 col-md-3 pt-3">
                <strong>
                  ประเภทคูปอง <span className="text-danger">*</span>
                </strong>
              </div>
              <div className="col-12 col-md-9 mt-3">
                <div className="row d-flex flex-column flex-md-row">
                  {couponOptions.map((option, index) => (
                    <div className="mb-3 col-md-6" key={index}>
                      <div
                        className={classes.radioContainer}
                        onClick={() => handleCouponTypeChange(option.value)}
                      >
                        <div className="d-flex align-items-center">
                          <div className={classes.iconContainer}>
                            <Radio
                              checked={couponType === option.value}
                              onChange={() =>
                                handleCouponTypeChange(option.value)
                              }
                              icon={<span className={classes.radioIcon} />}
                              checkedIcon={
                                <span className={classes.radioCheckedIcon}>
                                  <span className={classes.radioCheckedDot} />
                                </span>
                              }
                              name="couponType"
                            />
                          </div>
                          <label className="d-flex flex-column justify-content-center mb-0">
                            <p className={classes.labelText}>{option.label}</p>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Purchase Type */}
            <div
              className="row pb-6 mb-6"
              style={{
                borderBottom: "1px dashed #DADEEA",
                borderWidth: "3px",
              }}
            >
              <div className="col-12 col-md-3 pt-3">
                <strong>
                  ประเภทคำสั่งซื้อ <span className="text-danger">*</span>
                </strong>
              </div>
              {purchaseOptions.map((option, index) => (
                <div className="col-12 mt-3 mt-md-0 col-md-3" key={index}>
                  <div
                    className={classes.radioContainer}
                    onChange={() => handleCheckboxChange(option.key)}
                  >
                    <div className="d-flex align-items-center">
                      <div className={classes.iconContainer}>
                        <Checkbox
                          className={classes.customCheckbox}
                          checked={purchaseTypes[option.key] === 1}
                        />
                      </div>
                      <label className="d-flex flex-column justify-content-center mb-0">
                        <p className={classes.labelText}>{option.label}</p>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Order Menu */}

          <div className="container px-md-10">
            {(promotionTypeId === 14)  && (
              <DiscountDetail
                label="เมนูที่สั่งซื้อ"
                value={orderQuantity}
                unit="รายการ"
              />
            )}
            {(discountTypeId === 3 && promotionTypeId !== 14)&& (
              <DiscountDetail
                label="ลดราคา"
                value={targetFixedPrice}
                unit="บาท"
              />
            )}
            {(discountTypeId === 4 && promotionTypeId !== 14)&& (
              <DiscountDetail label="ลดราคา" value={targetPercent} unit="%" />
            )}
            {(discountConditionId === 12) && (
              <DiscountDetail
                label="ยอดซื้อขั้นต่ำ"
                value={orderQuantity}
                unit="บาท"
              />
            )}
            {(promotionTypeId === 2) && (
              <DiscountDetail
                label="จำนวนที่ต้องการ"
                value={orderQuantity}
                unit="รายการ"
              />
            )}
            <div className="row mb-3 mb-md-6">
              {/* Order Menu */}
              <div className="col-3 d-none d-md-block"></div>
              <div className="col-12 col-md-9 ">
                <div className="row pl-md-3 d-flex justify-content-center justify-content-md-start">
                  {discountConditionId === 5 &&
                    menuItems
                      .filter((item) => selectedOrderItems.includes(item.id))
                      .map((item, index) => (
                        <div
                          key={index}
                          className={`mb-3 col-10 col-md-4 ${
                            index < menuItems.length - 1 ? "mr-3" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "16px",
                          }}
                        >
                          {/* Image and Details */}
                          <div className="d-flex align-items-center">
                            <img
                              style={{
                                width: "43px",
                                height: "43px",
                                borderRadius: "8px",
                                marginRight: "16px",
                              }}
                              src={
                                item.item_image_path
                                  ? item.item_image_path
                                  : "/media/icon/picture-default.png"
                              }
                              alt={item.item_name}
                            />
                            <div>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  margin: "0",
                                }}
                              >
                                {item.item_name}
                              </p>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#757575",
                                  margin: "0",
                                }}
                              >
                                {item.price} THB
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  {discountConditionId === 6 &&
                    Object.entries(groupMenuMap)
                      .filter(([key]) => selectedOrderGroups.includes(key)) // ใช้ key เพื่อตรวจสอบ
                      .flatMap(([, items]) => items) // รวม items ทั้งหมดจาก key ที่ตรงกัน
                      .map((item, index) => (
                        <div
                          key={index}
                          className={`mb-3 col-10 col-md-4 ${
                            index < menuItems.length - 1 ? "mr-3" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "16px",
                          }}
                        >
                          {/* Image and Details */}
                          <div className="d-flex align-items-center">
                            <img
                              style={{
                                width: "43px",
                                height: "43px",
                                borderRadius: "8px",
                                marginRight: "16px",
                              }}
                              src={
                                item.item_image_path
                                  ? item.item_image_path
                                  : "/media/icon/picture-default.png"
                              }
                              alt={item.item_name}
                            />
                            <div>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  margin: "0",
                                }}
                              >
                                {item.item_name}
                              </p>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#757575",
                                  margin: "0",
                                }}
                              >
                                {item.price} THB
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>

          {/* Receive Menu */}
          {promotionTypeId === 14 && (
            <div className="container mt-3 px-md-10">
              {discountTypeId === 15 && 
              <DiscountDetail
                label="เมนูที่จะได้รับ"
                value={targetQuantity}
                unit="รายการ"
              />
              }
              {discountTypeId === 3 && 
              <DiscountDetail
                label="ลดราคา"
                value={targetFixedPrice}
                unit="บาท"
              />
              }
              {discountTypeId === 4 &&
              <DiscountDetail
                label="ลดราคา"
                value={targetPercent}
                unit="%"
              />}

              <div className="row mb-2">
                <div className="col-3 d-none d-md-block"></div>
                <div className="col-12 col-md-9">
                  <div className="row pl-md-3 d-flex justify-content-center justify-content-md-start">
                    {discountTargetId === 5 &&
                      menuItems
                        .filter((item) => selectedTargetItems.includes(item.id))
                        .map((item, index) => (
                          <div
                            key={index}
                            className={`mb-3 col-10 col-md-4 ${
                              index < menuItems.length - 1 ? "mr-3" : ""
                            }`}
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "16px",
                            }}
                          >
                            {/* Image and Details */}
                            <div className="d-flex align-items-center">
                              <img
                                style={{
                                  width: "43px",
                                  height: "43px",
                                  borderRadius: "8px",
                                  marginRight: "16px",
                                }}
                                src={
                                  item.item_image_path
                                    ? item.item_image_path
                                    : "/media/icon/picture-default.png"
                                }
                                alt={item.item_name}
                              />
                              <div>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    margin: "0",
                                  }}
                                >
                                  {item.item_name}
                                </p>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#757575",
                                    margin: "0",
                                  }}
                                >
                                  {item.price} THB
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    {discountTargetId === 6 &&
                      Object.entries(groupMenuMap)
                        .filter(([key]) => selectedTargetGroups.includes(key)) // ใช้ key เพื่อตรวจสอบ
                        .flatMap(([, items]) => items) // รวม items ทั้งหมดจาก key ที่ตรงกัน
                        .map((item, index) => (
                          <div
                            key={index}
                            className={`mb-3 col-10 col-md-4 ${
                              index < menuItems.length - 1 ? "mr-3" : ""
                            }`}
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "16px",
                            }}
                          >
                            {/* Image and Details */}
                            <div className="d-flex align-items-center">
                              <img
                                style={{
                                  width: "43px",
                                  height: "43px",
                                  borderRadius: "8px",
                                  marginRight: "16px",
                                }}
                                src={
                                  item.item_image_path
                                    ? item.item_image_path
                                    : "/media/icon/picture-default.png"
                                }
                                alt={item.item_name}
                              />
                              <div>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    margin: "0",
                                  }}
                                >
                                  {item.item_name}
                                </p>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#757575",
                                    margin: "0",
                                  }}
                                >
                                  {item.price} THB
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      <Step4Time />
      <Step4Option />
    </>
  );
}
