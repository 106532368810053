import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls/index.js";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers/index.js";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout/index.js";
import axios from "axios";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { baseURL, baseURLDASHBOARD } from "../service/API.js";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import NotFound from "./component/promotion/NotFound.js";
import PromotionList from "./component/promotion/PromotionList.js";

export const PromotionNew = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  const allData = [];
  const [promotion, setPromotion] = useState([]);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [openImage, setOpenImage] = useState(false);
  const [imgSelect, setImgSelect] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [selestSearch, setSelestSearch] = useState(1);
  const [newShop, setNewShop] = useState(true);

  suhbeader.setTitle(
    intl.formatMessage({ id: "113442", defaultMessage: "Promotion" })
  );
  const history = useHistory();

  useEffect(() => {
  const fetchData = async () => {
    localStorage.removeItem("edit");
    await getPromotionManagement(page, perPage);
  };
  fetchData();
}, [page, perPage]);

  const getPromotionManagement = async (page, perPage) => {
    setIsLoadingPage(true); // Start loading
    let apiUrls = `${baseURL}/management/promotionnew?limit=${perPage}&page=${page}${search !== undefined && search !== "" ? "&search=" + search : ""
      }`;
    let token = localStorage.getItem("Token");

    try {
      await axios({
        method: "get",
        url: apiUrls,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => { 
        console.log('getPromotionManagement : ', res.data.data)
        const allData = res.data.data.map((data, index) => ({
          id: data.id,
          key: index,
          shop_id: data.shop_id,
          branch_id: data.branch_id,
          promotion_name: data.promotion_name_th,
          promotion_desc: data.promotion_desc_th,
          start_date_time: moment(data.start_date_time).format("YYYY-MM-DD HH:mm:ss"),
          expiry_date_time: moment(data.expiry_date_time).format("YYYY-MM-DD HH:mm:ss"),
          created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          enabled: data.enabled,
        }));
        console.log('getPromotionManagement allData : ', allData)
        setIsLoadingPage(false);
        setPromotion(allData); // Update promotion data
        setTotal(res.data.total); // Update total items
        setLastPage(Math.ceil(Number(res.data.total) / Number(res.data.limit))); // Update pagination

        if(newShop && promotion.length > 0){
          setNewShop();
        }
      })
    } catch (error) {
      setIsLoadingPage(false);
      console.error(error);
    }
    // finally {
    //   setIsLoadingPage(false);
    // }
  };

  const getMenuManagementPage = async (p) => {
    getPromotionManagement(p, perPage);
  };

  const getMenuManagementPerPage = async (p) => {
    setPerPage(p);
    getPromotionManagement(page, p);
  };

  const columns = [
    {
      dataField: "promotion_name",
      text: "name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "promotion_desc",
      text: "description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "start_date_time",
      text: "start date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "expiry_date_time",
      text: "end date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div className="form-inline">
            <div className="mr-3">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => viewItem({ row, rowIndex })}
              >
                View
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => deleteItem({ row, rowIndex })}
              >
                Delete
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setId("");
    setOpenImage(false);
    setImgSelect("");
  };

  const editItem = (data) => {
    history.push("/EditMenuOptionTemplate", data);
  };

  const deleteItem = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Menu Management !",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/management/promotionnew/${data.row.id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })

          .then((res) => {
            console.log("res after delete==========>", res);
            Swal.fire(
              "Deleted!",
              "Your Menu Management has been deleted.",
              "success"
            );
            getPromotionManagement(page, perPage);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Menu Management has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Menu Management is safe :)", "error");
      }
    });
  };

  const viewItem = (data) => {
    // history.push("/ViewPromotion", data);
    history.push("/ViewPromotionV2", data);
  };

  const [search, setSearch] = useState();
  const handleSearch = () => {
    getPromotionManagement(page, perPage); 
  };

  const onChanges = (e) => {
    setPerPage(e.target.value);
    getMenuManagementPerPage(e.target.value);
  };

  const pagination = paginationFactory({
    page: currentPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={currentPage}
              totalPages={lastPage}
              changeCurrentPage={async (numPage) => {
                onPageChange(numPage);
                setPage(numPage);
                setCurrentPage(numPage);
                getMenuManagementPage(numPage);
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      );
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              value={perPage}
              onChange={(e) => {
                // console.log('sizePerPageRenderer : ', e.target.value)
                onChanges(e);
                onSizePerPageChange(e.target.value);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    ),
  });

  const handleChangeSelestSearch = (event) => {
    setSelestSearch(Number(event.target.value));
  };

  return (
    <>
      {isLoadingPage ? (
        <div className="d-flex justify-content-center align-items-center top-0 left-0 w-100 h-100 bg-white bg-opacity-75">
          <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Loading...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      ) : promotion.length > 0 || !newShop ? (
        <PromotionList
          handleClose={handleClose}
          intl={intl}
          promotion={promotion}
          search={search}
          setSearch={setSearch}
          columns={columns}
          pagination={pagination}
          handleSearch={handleSearch}
          openImage={openImage}
          setOpenImage={setOpenImage}
          imgSelect={imgSelect}
          setImgSelect={setImgSelect}
          Card={Card}
          CardHeader={CardHeader}
          CardBody={CardBody}
          CardHeaderToolbar={CardHeaderToolbar}
          PleaseWaitMessage={PleaseWaitMessage}
          NoRecordsFoundMessage={NoRecordsFoundMessage}
        />
      ) : (
        <NotFound
          isFetch={true}
          title="No promotion"
          description="Create a promotional list to promote sales"
        />
      )}
    </>
  );
};
