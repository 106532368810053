import {
  ArrowDropDownCircleOutlined,
} from "@material-ui/icons";
import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Checkbox from "@material-ui/core/Checkbox";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext";

export const OrderButtonModal = () => {
  const {
    menuGroup,
    groupMenuMap,
    discountConditionId,
    selectedOrderItems,
    selectedOrderGroups,
    handleSelectOrderItem,
    handleSelectOrderGroup,
    handleSelectOrderItemByGroupId,
  } = useCreatePromotionContext();

  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [collapsedGroups, setCollapsedGroups] = useState({}); // สถานะการย่อ/ขยายของแต่ละกลุ่ม

  const toggleGroup = (groupId) => {
    setCollapsedGroups((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId], // toggle true/false สำหรับ groupId
    }));
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      {discountConditionId === 5 && (
        <>
          <button
            className="btn btn-primary"
            onClick={() => setShow(true)}
            style={{
              width: "90px",
              height: "38px",
              padding: "10px",
              borderRadius: "6px",
              backgroundColor: "#0D6EFD",
              margin: 0,
              opacity: 0.8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0px",
            }}
          >
            <p
              style={{
                fontFamily: "Sarabun, sans-serif",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "18.2px",
                textAlign: "center",
                margin: 0,
              }}
            >
              เลือกเมนู
            </p>
          </button>

          <Modal
            open={show}
            onClose={() => setShow(false)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              style: { backgroundColor: "rgba(0, 0, 0, 0.2)" },
            }}
          >
            <Fade in={show}>
              <div
                style={{
                  width: "400px",
                  height: "100%",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  padding: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "80px",
                    borderBottom: "1px solid #F1F1F4",
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  <span>เลือกเมนู</span>
                  <img
                    src={"/media/mockup/cross.jpg"}
                    alt="Close"
                    onClick={() => setShow(false)}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                {/* Search Input */}
                <input
                  type="text"
                  placeholder="ค้นหาเมนู"
                  value={searchQuery}
                  onChange={handleSearch}
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "16px 0",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />

                <div
                  style={{
                    maxHeight: "75%",
                    overflowY: "auto",
                    paddingRight: "16px",
                    marginBottom: "16px",
                  }}
                >
                  {/* แสดงเมนูตามกลุ่มใน groupMenuMap โดยใช้ searchQuery ในการกรอง */}
                  {Object.entries(groupMenuMap).map(([groupId, items]) => {
                    const group = menuGroup.find(
                      (g) => g.value === parseInt(groupId)
                    );
                    const groupName = group ? group.label : `Group ${groupId}`;

                    // กรอง items ตาม searchQuery
                    const filteredItems = items.filter((item) =>
                      item.item_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    );

                    // ถ้าไม่มี items ที่ตรงกับคำค้นหา จะไม่แสดงกลุ่มนี้
                    if (filteredItems.length === 0) return null;

                    const isCollapsed = collapsedGroups[groupId];

                    return (
                      <div key={groupId} style={{ marginBottom: "16px" }}>
                        <h4
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#6E6E6E",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          >
                          <div>
                          <Checkbox
                            checked={selectedOrderGroups.includes(groupId)}
                            onChange={() =>
                              handleSelectOrderItemByGroupId(groupId)
                            }
                            // ต้องสร้างฟังก์ชัน ที่ handleSelectOrderItemByGroupId โดย add order item ทุกตัวเข้าไปที่ selectOrderItems ที่มี groupId
                          />
                            <span>{groupName}</span>
                            <span style={{ marginLeft: "8px" }}>
                              ({filteredItems.length} รายการ)
                            </span>
                          </div>
                          <button
                            onClick={() => toggleGroup(groupId)}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "#007bff",
                              cursor: "pointer",
                              fontSize: "12px",
                            }}
                          >
                            {isCollapsed ? (
                              <ArrowDropDownCircleOutlined
                                style={{
                                  color: "#6E6E6E",
                                  fontSize: "24px",
                         
                                }}
                              />
                            ) : (
                              <ArrowDropDownCircleOutlined
                                style={{
                                  color: "#6E6E6E",
                                  fontSize: "24px",
                                  transform: isCollapsed
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)",
                                }}
                              />
                            )}{" "}
                          </button>
                        </h4>
                        {!isCollapsed &&
                          filteredItems.map((item) => (
                            <div
                              key={item.id}
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: "12px",
                                padding: "8px",
                                marginBottom: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={
                                  item.item_image_path
                                    ? item.item_image_path
                                    : "/media/icon/picture-default.png"
                                }
                                alt={item.item_name}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "10px",
                                  borderRadius: "8px",
                                }}
                              />
                              <div
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <strong>{item.item_name}</strong>
                                <p style={{ margin: 0 }}>{item.price} THB</p>
                              </div>
                              <Checkbox
                                checked={
                                  selectedOrderItems.includes(item.id) ||
                                  selectedOrderGroups.includes(item.group_id)
                                }
                                onChange={() => handleSelectOrderItem(item.id)}
                              />
                            </div>
                          ))}
                      </div>
                    );
                  })}
                </div>

                <div
                  className={`btn text-center ${
                    selectedOrderItems?.length ? "btn-primary" : "btn-secondary"
                  }`}
                  aria-disabled={!selectedOrderItems?.length}
                  style={{
                    width: "100%",
                    padding: "0.75rem",
                    borderRadius: "6px",
                    cursor: selectedOrderItems?.length
                      ? "pointer"
                      : "not-allowed",
                    marginTop: "16px",
                  }}
                  onClick={() => {
                    if (selectedOrderItems?.length) setShow(false);
                  }}
                >
                  ยืนยัน
                </div>
              </div>
            </Fade>
          </Modal>
        </>
      )}
      {discountConditionId === 6 && (
        <>
          <button
            className="btn btn-primary"
            onClick={() => setShow(true)}
            style={{
              width: "90px",
              height: "38px",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "6px",
              backgroundColor: "#0D6EFD",
              margin: 0,
              opacity: 0.8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0px",
            }}
          >
            <p
              style={{
                fontFamily: "Sarabun, sans-serif",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "18.2px",
                textAlign: "center",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              เลือกหมวดหมู่
            </p>
          </button>

          <Modal
            open={show}
            onClose={() => setShow(false)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              style: { backgroundColor: "rgba(0, 0, 0, 0.2)" },
            }}
          >
            <Fade in={show}>
              <div
                style={{
                  width: "400px",
                  height: "100%",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  padding: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "80px",
                    borderBottom: "1px solid #F1F1F4",
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  <span>เลือกหมวดหมู่</span>
                  <img
                    src={"/media/mockup/cross.jpg"}
                    alt="Close"
                    onClick={() => setShow(false)}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                {/* Search Input */}
                <input
                  type="text"
                  placeholder="ค้นหาหมวดหมู่"
                  value={searchQuery}
                  onChange={handleSearch}
                  style={{
                    width: "100%",
                    padding: "8px",
                    margin: "16px 0",
                    borderRadius: "4px",
                    border: "1px solid #ddd",
                  }}
                />

                <div
                  style={{
                    maxHeight: "75%",
                    overflowY: "auto",
                    paddingRight: "16px",
                    marginBottom: "16px",
                  }}
                >
                  {/* แสดงหมวดหมู่เท่านั้น */}
                  {Object.entries(groupMenuMap).map(([groupId, items]) => {
                    const group = menuGroup.find(
                      (g) => g.value === parseInt(groupId)
                    );
                    const groupName = group ? group.label : `Group ${groupId}`;

                    // กรองเฉพาะหมวดหมู่ตาม searchQuery
                    if (
                      !groupName
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                      return null;

                    return (
                      <div key={groupId} style={{ marginBottom: "16px" }}>
                        <h4
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#6E6E6E",
                          }}
                        >
                          <Checkbox
                            checked={selectedOrderGroups.includes(groupId)}
                            onChange={() => handleSelectOrderGroup(groupId)}
                          />
                          <span>{groupName}</span>
                        </h4>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={`btn text-center ${
                    selectedOrderGroups?.length
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  aria-disabled={!selectedOrderGroups?.length}
                  style={{
                    width: "100%",
                    padding: "0.75rem",
                    borderRadius: "6px",
                    cursor: selectedOrderGroups?.length
                      ? "pointer"
                      : "not-allowed",
                    marginTop: "16px",
                  }}
                  // onClick={() => setShow(false)}
                  onClick={() => {
                    if (selectedOrderGroups?.length) setShow(false);
                  }}
                >
                  ยืนยัน
                </div>
              </div>
            </Fade>
          </Modal>
        </>
      )}
    </div>
  );
};
