import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";

export const CreatePurchaseRequest = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113383" ,defaultMessage:"Purchase Request"})
  );
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);
  const [shopList, setShopList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [shop, setShop] = useState(null);
  const [shopShow, setShopShow] = useState(null);
  const [branch, setBranch] = useState(null);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [quantity, setQty] = useState(null);
  const [price_per_unit, setPricePerUnit] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('');
  const [plan , setPlan] = useState(null);
  const [planList, setPlanList] = useState([]);
  const handleChangeShop = (event, values) => {
    console.log(event, values);
    if (values) {
      setShopShow(values)
      setShop(values.id);
      setBranch(values.posBranch.id)
    } else {
      setShop(null);
    }
  };

  const handleChangeItem = (event, values) => {
    console.log(values.id, values);
    if (values) {
      setItem({id:values.id, name: values.name});
      setPricePerUnit(values.price_per_main_unit);
    } else {
      setItem(null);
    }
  };

  const handleChangePlan = (event, values) => {
    console.log(values.id, values);
    if (values) {
      setPlan({id:values.id, name: values.name});
      getPlanItems(values.id);
    } else {
      setItem(null);
    }
  };

  const getPlanItems = async (id) => {
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: `${baseURL}/purchase/plan?id=${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          console.log(res.data.data);
          if(listData.length !== 0){

            let temp = listData;
            res.data.data.map((data, index) => {
              console.log('list != 0')
              console.log(data)
              temp.push({
                index: maxIndex+1,
                item_id: data.material_id,
                name: data.name,
                quantity: data.quantity,
                price_per_unit:data.price_per_unit,
              })
              
              
            });
            setListData(temp)
            setMaxIndex(maxIndex+1)
            setQty(0)
            setPricePerUnit(0)
            setIsAdd(true)
            setItem(null)
          }else{
            const updatedMaterials = res.data.data.map(material => {
                setMaxIndex(maxIndex+1)
                return {
                    index: maxIndex,
                    item_id: material.material_id,
                    name: material.name,
                    name_en: material.name_en,
                    price_per_unit: material.price_per_unit,
                    quantity: material.quantity
                };
            });
            console.log(updatedMaterials)
              
            setListData(updatedMaterials);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    gettingItem();
    gettingData();
  }, []);

  const columns = [
    {
      dataField: "item_id",
      text: "ID",
    },

    {
      dataField: "name",
      text: "item name",
    },

    {
      dataField: "quantity",
      text: "quantity",
    },

    {
      dataField: "price_per_unit",
      text: "price per unit",
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem(rowIndex)}
            >
              {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
            </button>
          </div>
        );
      },
    },
  ];

  const deleteItem = async(rowIndex) => {
    let temp = []
    console.log(listData)
    var array = [...listData]; // make a separate copy of the array
    array.splice(rowIndex, 1);
    setListData(array);
  }

  const gettingItem = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/purchase/pr/item_list`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setitemList(res.data.data);
    });
  };

  const gettingData = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/purchase/plan`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setPlanList(res.data.data)
    });
  };

  const AlertError = (message) => {
    Swal.fire("Create Purchase Request!", message, "error");
  };
  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleAdd = async() => {
    /*setListData(listData => [...listData, {
      index: maxIndex+1,
      item_id: item.id,
      item_name: item.item_name,
      qty: qty,
    }])*/
    if (!item) {
      await AlertError('please insert item')
    } else if (!quantity || quantity == 0) {
      await AlertError('please insert quantity ')
    }else if (!price_per_unit || price_per_unit == 0) {
      await AlertError('please insert price per unit ')
    } else {
      let temp = listData
      temp.push({
        index: maxIndex+1,
        item_id: item.id,
        name: item.name,
        quantity: quantity,
        price_per_unit:price_per_unit,
      })
      setListData(temp)
      setMaxIndex(maxIndex+1)
      setQty(0)
      setPricePerUnit(0)
      setIsAdd(true)
      setItem(null)
      //setDescription('')
      //setShop(null)
      console.log(listData)
    }
    
  }

  const handleSend = async () => {
    /*if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }*/
    let now= new Date()
    var events = {
      //order_no: "10000",
      description: description == '' ? 'none' : description,
      list: listData,
      send_shop_id: shop,
      send_branch_id: branch,
  }

    let apiUrls = `${baseURL}/purchase/pr`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Create Purchase Request success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Create Purchase Request. !! " + err,
          "error"
        );
      });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113386",
            defaultMessage: "Create Purchase Request",
          })}
        ></CardHeader>
        <CardBody>
        <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113397",
                  defaultMessage: "Purchase Plan",
                })}
                :{" "}
              </p>
              {/* <div> */}
                <Autocomplete
                  id="grouped-unit"
                  disableClearable
                  // id="purchase_plan"
                  options={planList}
                  value={plan}
                  getOptionLabel={(option) =>
                    option.name ? option.name : "ไม่มีชื่อ"
                  }
                  // style={{
                  //   height: "38px",
                  //   width: "530px",
                  //   backgroundColor: "#F4F6F9",
                  //   borderRadius: "5px",
                  //   borderWidth: "0",
                  // }}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    // borderWidth: "0",
                  }}
                  onChange={handleChangePlan}
                  // renderInput={(params) => (
                  //   <TextField
                  //     {...params}
                  //     style={{
                  //       borderWidth: 0,
                  //     }}
                  //     label="plan"
                  //   />
                  // )}
                   renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                />
              {/* </div> */}
            </div>
          </div>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
            
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113169" ,defaultMessage:"Description" })}:{" "}
              </p>
              <input
                name="description"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                type="textarea"
                value={description}
                onChange={(table_name) => setDescription(table_name.target.value)}
              />
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })}
                :{" "}
              </p>
              {/* <div> */}
                <Autocomplete
                  id="grouped-unit"
                  disableClearable
                  // id="zone printer"
                  options={itemList}
                  value={item}
                  getOptionLabel={(option) =>
                    option.name ? option.name : "ไม่มีชื่อ"
                  }
                  // style={{
                  //   height: "38px",
                  //   width: "530px",
                  //   backgroundColor: "#F4F6F9",
                  //   borderRadius: "5px",
                  //   borderWidth: "0",
                  // }}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    // borderWidth: "0",
                  }}
                  onChange={handleChangeItem}
                  // renderInput={(params) => (
                  //   <TextField
                  //     {...params}
                  //     style={{
                  //       borderWidth: 0,
                  //     }}
                  //     label="item"
                  //   />
                  // )}
                  renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                />
              {/* </div> */}
            </div>
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={quantity}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setQty(e.target.value)
                }
              />
            </div>

            <hr />            
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113400",
                  defaultMessage: "Price per Unit",
                })}
                :{" "}
              </p>
              <input
                value={price_per_unit}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setPricePerUnit(e.target.value)
                }
              />
              <button
                className="ml-2 btn btn-primary"
                onClick={() => handleAdd()}
              >
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>

            <hr />            
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/PurchaseRequest">
                <button type="button" className="btn btn-secondary wbt">
                  {intl.formatMessage({
                    id: "107016",
                    defaultMessage: "Cancel",
                  })}
                </button>
              </Link>
              <button className="btn btn-primary" onClick={() => handleSend()}>
                {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
              </button>
            </div>
        </CardBody>
      </Card>
    </div>
  );
};
