import React, { useEffect } from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import { useCreatePromotionContext } from "../../../context/CreatePromotionContext";

const useStyles = makeStyles({
  customSelect: {
    height: "44px",
    paddingRight: "30px",
    backgroundPosition: "calc(100% - 12px) center",
    backgroundRepeat: "no-repeat",
    backgroundImage:
      "url('data:image/svg+xml;charset=UTF-8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2216%22 height=%2216%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22%23333%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22><polyline points=%226 9 12 15 18 9%22 /></svg>')",
    appearance: "none",
    border: "1px solid #E5E7EB",
    padding: "10px 16px",
    borderRadius: "8px",
    fontFamily: "Sarabun, sans-serif",
    fontSize: "14px",
    color: "#333",
  },
});

export const Step4Time = () => {
  const classes = useStyles();

  const {
    startDate2,
    setStartDate2,
    expiryDate2,
    setExpiryDate2,
    dayType2,
    setDayType2,
    timeType2,
    setTimeType2,
  } = useCreatePromotionContext();

  const handleExpiryDateChange = (value) => {
    setExpiryDate2(value);
    
  };


  return (
    <>
      <div
        style={{
          width: "100%",
          height: "70px",
          borderBottom: "1px solid #EBEDF3",
          marginTop: "10px",
          padding: "2rem 0 2rem 0",
          backgroundColor: "#FFFFFF",
        }}
      >
        <strong className="promotion-sarabun-strong">วันที่และเวลา</strong>
      </div>

      <div
        className="container px-10 py-6 border rounded"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <form>
          <div className="mt-7 row">
            <label className="col-md-3 font-weight-bold promotion-sarabun-text">
              ระยะเวลาโปรโมชั่น
              <span className="text-danger">*</span>
            </label>
            <div className="col-md-3 font-weight-bold" style={{ marginBottom: "10px"}}>
              <input
                type="datetime-local" //เอาเวลาที่สร้างด้วย (+7 ชม ไว้แล้ว)
                className="form-control"
                value={startDate2}
                onChange={(e) => setStartDate2(e.target.value)}
              />
            </div>
            <div className="col-md-3 font-weight-bold">
              <input
                type="datetime-local" //เอาเวลาที่สร้างด้วย (+7 ชม ไว้แล้ว)
                className="form-control"
                value={expiryDate2}
                onChange={(e) => handleExpiryDateChange(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-7 row">
            <label className="col-md-3 font-weight-bold promotion-sarabun-text">
              วัน
              <span className="text-danger">*</span>
            </label>
            <div className="col-md-3 font-weight-bold">
              <select
                className={`form-control ${classes.customSelect}`}
                value={dayType2}
                onChange={(e) => setDayType2(e.target.value)}
              >
                <option>ทุกวันที่ร้านเปิดทำการ</option>
                <option>เฉพาะวันธรรมดา</option>
                <option>เฉพาะวันหยุดสุดสัปดาห์</option>
              </select>
            </div>
          </div>

          <div className="mb-7 mt-7 row">
            <label className="col-md-3 font-weight-bold promotion-sarabun-text">
              เวลา
              <span className="text-danger">*</span>
            </label>
            <div className="col-md-3 font-weight-bold4">
              <select
                className={`form-control ${classes.customSelect}`}
                value={timeType2}
                onChange={(e) => setTimeType2(e.target.value)}
              >
                <option>ทุกเวลาที่ร้านเปิดทำการ</option>
                <option>00:00 - 23:59</option>
                <option>00:00 - 11:59</option>
                <option>12.00 - 23:59</option>
                <option>00:00 - 05:59</option>
                <option>06:00 - 11:59</option>
                <option>12:00 - 17:59</option>
                <option>18:00 - 23:59</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
