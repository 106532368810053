import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseURL, imageURL } from "../service/API";
import Swal from "sweetalert2";

const CreatePromotionContext = createContext();

export const CreatePromotionProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [promotionTypeId, setPromotionTypeId] = useState(null); // step 1_1/1
  const [promotionTypeName_en, setPromotionTypeNameEn] = useState(null);
  const [promotionTypeName_th, setPromotionTypeNameTh] = useState(null);

  const [discountTypeId, setDiscountTypeId] = useState(null); // step 2_1/3
  const [discountConditionId, setDiscountConditionId] = useState(null); // step 2_2/3
  const [discountTargetId, setDiscountTargetId] = useState(null); // step 2_3/3
  const [discountTypeName_en, setDiscountTypeNameEn] = useState(null);
  const [discountTargetName_en, setDiscountTargetNameEn] = useState(null);
  const [discountConditionName_en, setDiscountConditionNameEn] = useState(null);
  const [discountTypeName_th, setDiscountTypeNameTh] = useState(null);
  const [discountTargetName_th, setDiscountTargetNameTh] = useState(null);
  const [discountConditionName_th, setDiscountConditionNameTh] = useState(null);

  const [menuGroup, setMenuGroup] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [promotionSet, setPromotionSet] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const currentDate = new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [expiryDate, setExpiryDate] = useState(currentDate);

  const [promotionNameTH, setPromotionNameTH] = useState("");
  const [promotionNameEN, setPromotionNameEN] = useState("");
  const [promotionDescTH, setPromotionDescTH] = useState("");
  const [promotionDescEN, setPromotionDescEN] = useState("");
  const [amountValue, setAmountValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  const [eventPromotion, setEventPromotion] = useState([]);
  const [eventDiscountType, setEventDiscountType] = useState([]);
  const [eventDiscountCondition, setEventDiscountCondition] = useState([]);
  const [eventDiscountTarget, setEventDiscountTarget] = useState([]);

  const [selectedBundle, setSelectedBundle] = useState(null);

  const [selectedOrderItems, setSelectedOrderItems] = useState([]); // step 3_1/8
  const [selectedTargetItems, setSelectedTargetItems] = useState([]); // step 3_2/8
  const [selectedOrderGroups, setSelectedOrderGroups] = useState([]); // step 3_3/8
  const [selectedTargetGroups, setSelectedTargetGroups] = useState([]); // step 3_4/8

  const [groupMenuMap, setGroupMenuMap] = useState({});
  const [originalGroupMenuMap, setOriginalGroupMenuMap] = useState({});

  const [orderQuantity, setOrderQuantity] = useState("1"); // step 3_5/8
  const [targetQuantity, setTargetQuantity] = useState("1"); // step 3_6/8
  const [targetFixedPrice, setTargetFixedPrice] = useState("1"); // step 3_7/8
  const [targetPercent, setTargetPercent] = useState("1"); // step 3_8/8

  const [promotionName, setPromotionName] = useState(""); // step 4_2/17
  const [promotionDescription, setPromotionDescription] = useState(""); // step 4_3/17

  const [couponType, setCouponType] = useState("PRIVATE"); // step 4_4/17
  const [purchaseTypes, setPurchaseTypes] = useState({
    isDineIn: 1, // step 4_5/17
    isPickUp: 1, // step 4_6/17
    isTakeAway: 1, // step 4_7/17
  });
  const purchaseOptions = [
    {
      label: "ทานที่ร้าน",
      key: "isDineIn",
    },
    {
      label: "รับที่ร้าน",
      key: "isPickUp",
    },
    {
      label: "บริการจัดส่ง",
      key: "isTakeAway",
    },
  ];
  const couponOptions = [
    {
      label: "สาธารณะ (ลูกค้าเป็นคนกดโปรโมชั่น)",
      value: "PUBLIC",
    },
    {
      label: "ส่วนตัว (พนักงานเป็นคนกดโปรโมชั่น)",
      value: "PRIVATE",
    },
  ];

  const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
  const [item_image_path, setItem_image_path] = useState("");

  const [stateItem_image_path, setStateItem_image_path] = useState({
    previewItem_image_path: null,
    rawItem_image_path: null,
    imageURLItem_image_path: null, // step 4_1/15
  });

  const [startDate2, setStartDate2] = useState(() => {
    const now = new Date();
    now.setHours(now.getHours() + 7);
    // return now.toISOString().slice(0, 10); //แค่วัน
    return now.toISOString().slice(0, 16); // อันนี้คือรวมเวลาด้วย
  }); // step 4_8/17
  const [expiryDate2, setExpiryDate2] = useState(""); // step 4_9/17
  const [dayType2, setDayType2] = useState("ทุกวันที่ร้านเปิดทำการ"); // step 4_10/17
  const [timeType2, setTimeType2] = useState("ทุกเวลาที่ร้านเปิดทำการ"); // step 4_11/17

  const [checkedRedemption, setCheckedRedemption] = useState(false); // step 4_12/17
  const [checkedItemsLimitPerDay, setCheckedItemsLimitPerDay] = useState(false); // step 4_13/17
  const [checkedAutoApply, setCheckedAutoApply] = useState(false); // step 4_14/17
  const [redemptionDefault, setRedemptionDefault] = useState(1); // step 4_15/17
  const [itemsLimitPerDayDefault, setItemsLimitPerDayDefault] = useState(1); // step 4_16/17
  const [selectedButton, setSelectedButton] = useState(1); // step 4_17/17 //
  const [checkedIncludeOption, setCheckedIncludeOption] = useState(false); //step 4_18/18

  const changeStep = (direction, setIsLoadingPage) => {
    //เปลี่ยนหน้่า curr&active // เปลี่ยนหน้าย่อย change only subcurr
    if (direction === "next") {
      console.log("Go Next");
      console.log("current step", currentStep);
      // console.log("sub current page", subCurrentPage);
      console.log("active step", activeStep);
      if (currentStep < 5) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
      }
      if (currentStep === 5) {
        setIsLoadingPage(true);
        setTimeout(() => {
          window.location.href = "/PromotionNew";
        }, 500); 
        return;
      }
    } else if (direction === "back") {
      console.log("Go Back");
      // console.log("current step", currentStep)
      // console.log("sub current page", subCurrentPage)
      // console.log("active step", activeStep)
      if (currentStep > 1) {
        if (currentStep === 2) {
          setDiscountTypeId(null);
          setDiscountTargetId(null);
          setDiscountConditionId(null);
          // setSelectedBundle(null);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
        } else if (currentStep === 3) {
          setSelectedOrderItems([]);
          setSelectedTargetItems([]);
          setSelectedOrderGroups([]);
          setSelectedTargetGroups([]);
          setSelectedBundle(null);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
        } else if (currentStep === 5) {
          //  && subCurrentPage === 0)
          // setSubCurrentPage(1);
          setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else if (currentStep === 4) {
          // if (subCurrentPage > 0) {
          //   setSubCurrentPage((prevSubCurrentPage) => prevSubCurrentPage - 1);
          // } else {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
          // }
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
        }
      }
      if (currentStep === 1) {
        window.location.href = "/PromotionNew";
        return;
      }
    }
  };

  const handleNextStep = () => {
    changeStep("next");
  };

  const handleBackStep = () => {
    changeStep("back");
  };

  const alertError = (message) => {
    Swal.fire("!", message, "error");
  };

  const handlePromotionTypeSelect = (promotion_type_id) => {
    const selectedPromotion = eventPromotion.find(
      (promotion) => promotion.promotion_type_id === promotion_type_id
    );
    const promotionNameEn = selectedPromotion
      ? selectedPromotion.name_en
      : null;
    const promotionNameTh = selectedPromotion
      ? selectedPromotion.name_th
      : null;
    setPromotionTypeId(promotion_type_id);
    setPromotionTypeNameEn(promotionNameEn);
    setPromotionTypeNameTh(promotionNameTh);
  };

  const handleDiscountConditionSelect = (discount_id) => {
    const selectedDiscount = eventDiscountCondition.find(
      (discount) => discount.discount_condition_id === discount_id
    );
    const discountNameEn = selectedDiscount ? selectedDiscount.name_en : null;
    const discountNameTh = selectedDiscount ? selectedDiscount.name_th : null;
    setDiscountConditionId(discount_id);
    setDiscountConditionNameEn(discountNameEn);
    setDiscountConditionNameTh(discountNameTh);
  };

  const handleDiscountTargetSelect = (discount_id) => {
    const selectedDiscount = eventDiscountTarget.find(
      (discount) => discount.discount_condition_id === discount_id
    );
    const discountNameEn = selectedDiscount ? selectedDiscount.name_en : null;
    const discountNameTh = selectedDiscount ? selectedDiscount.name_th : null;
    setDiscountTargetId(discount_id);
    setDiscountTargetNameEn(discountNameEn);
    setDiscountTargetNameTh(discountNameTh);
  };

  const handleDiscountTypeSelect = (discount_type_id) => {
    const selectedDiscount = eventDiscountType.find(
      (discount) => discount.discount_type_id === discount_type_id
    );
    const discountTypeNameEn = selectedDiscount
      ? selectedDiscount.name_en
      : null;
    const discountTypeNameTh = selectedDiscount
      ? selectedDiscount.name_th
      : null;
    setDiscountTypeId(discount_type_id);
    // setDiscountTargetId(discount_target_id);
    setDiscountTypeNameEn(discountTypeNameEn);
    setDiscountTypeNameTh(discountTypeNameTh);
  };

  const handleOrderQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setOrderQuantity(!isNaN(value) ? value : "");
  };
  const handleTargetQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTargetQuantity(!isNaN(value) ? value : "");
  };

  const handleTargetFixedPriceChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTargetFixedPrice(!isNaN(value) ? value : "");
  };

  const handleTargetPercentChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTargetPercent(!isNaN(value) ? value : "");
  };

  const handleSelectOrderItem = (id) => {
    setSelectedOrderItems((prev) => {
      let updatedItems = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      console.log("Updated selected order items:", updatedItems);
      return updatedItems;
    });
  };
  const handleSelectOrderGroup = (id) => {
    setSelectedOrderGroups((prev) => {
      let updatedItems = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      console.log("Updated selected order gropus:", updatedItems);
      return updatedItems;
    });
  };

  const handleSelectTargetItem = (id) => {
    setSelectedTargetItems((prev) => {
      let updatedItems = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      console.log("Updated selected target items:", updatedItems);
      return updatedItems;
    });
  };
  const handleSelectTargetGroup = (id) => {
    setSelectedTargetGroups((prev) => {
      let updatedItems = prev.includes(id)
        ? prev.filter((item) => item !== id)
        : [...prev, id];

      console.log("Updated selected target groups:", updatedItems);
      return updatedItems;
    });
  };

  const handleRemoveOrderItems = (id) => {
    setSelectedOrderItems((prev) => prev.filter((item) => item !== id));
  };
  const handleRemoveOrderGroups = (id) => {
    setSelectedOrderGroups((prev) =>
      prev.filter((item) => item !== id.toString())
    );
  };
  const handleRemoveTargetItems = (id) => {
    setSelectedTargetItems((prev) => prev.filter((item) => item !== id));
  };
  const handleRemoveTargetGroups = (id) => {
    setSelectedTargetGroups((prev) =>
      prev.filter((item) => item !== id.toString())
    );
  };

  const handleSelectOrderItemByGroupId = (groupId) => {
    const itemsInGroup = groupMenuMap[groupId]?.map((item) => item.id) || [];

    setSelectedOrderGroups((prevGroups) => {
      const isGroupSelected = prevGroups.includes(groupId);

      // Toggle group selection
      const updatedGroups = isGroupSelected
        ? prevGroups.filter((g) => g !== groupId)
        : [...prevGroups, groupId];

      // Update selected items based on group selection
      setSelectedOrderItems((prevItems) => {
        return isGroupSelected
          ? prevItems.filter((itemId) => !itemsInGroup.includes(itemId))
          : [...new Set([...prevItems, ...itemsInGroup])];
      });

      return updatedGroups;
    });
  };

  const handleSelectTargetItemByGroupId = (groupId) => {
    const itemsInGroup = groupMenuMap[groupId]?.map((item) => item.id) || [];

    setSelectedTargetGroups((prevGroups) => {
      const isGroupSelected = prevGroups.includes(groupId);

      // Toggle group selection
      const updatedGroups = isGroupSelected
        ? prevGroups.filter((g) => g !== groupId)
        : [...prevGroups, groupId];

      // Update selected items based on group selection
      setSelectedTargetItems((prevItems) => {
        return isGroupSelected
          ? prevItems.filter((itemId) => !itemsInGroup.includes(itemId))
          : [...new Set([...prevItems, ...itemsInGroup])];
      });

      return updatedGroups;
    });
  };

  const getPromotion = async () => {
    let apiUrls = `${baseURL}/management/promotionTypeNew`;
    let token = localStorage.getItem("Token");

    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        const getIconPath = (promotionTypeId) => {
          switch (promotionTypeId) {
            case 1:
              return "./media/icon/discount-byx3.png";
            case 2:
              return "./media/icon/bundle-offerx3.png";
            case 14:
              return "./media/icon/item-to-itemx3.png";
            default:
              return "./media/icon/promotion.jpg";
          }
        };

        let newState = res.data.data.map((promotion) => ({
          text: promotion.name_en,
          description: promotion.desc_en,
          promotion_type_id: promotion.promotion_type_id,
          name_en: promotion.name_en,
          name_th: promotion.name_th,
          icon: getIconPath(promotion.promotion_type_id),
          options:
            promotion.options?.map((option) => ({
              text: option.name_en,
              promotion_type_id: option.promo_type_id,
            })) || [],
        }));
        setEventPromotion(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDiscount = async () => {
    let apiUrls = `${baseURL}/management/discountTypeNew`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        console.log(
          "***********getDiscount********",
          res.data.discountTypeDetail,
          res.data.discountTargetDetail
        );

        //// eventDiscountType
        const getIconPath = (id) => {
          switch (id) {
            case 3:
              return "./media/icon/fixed-pricex3.png";
            case 4:
              return "./media/icon/percentx3.png";
            case 5:
              return "./media/icon/itemx3.png";
            case 6:
              return "./media/icon/categoryx3.png";
            case 15:
              return "./media/icon/freex3.png";
            default:
              return "./media/icon/discount.jpg";
          }
        };
        let newStateType = res.data.discountTypeDetail.map((discount) => ({
          title: discount.name_en,
          name_en: discount.name_en,
          name_th: discount.name_th,
          detail: discount.desc_en,
          discount_type_id: discount.discount_type_id,
          icon: getIconPath(discount.discount_type_id),
        }));
        console.log("discount Type data======>", newStateType);
        setEventDiscountType(newStateType);

        //// eventDiscountCondition & eventDiscountCondition
        let newStateCondition = res.data.discountTargetDetail.map(
          (discount) => ({
            title: discount.name_en,
            name_en: discount.name_en,
            name_th: discount.name_th,
            detail: discount.desc_en,
            discount_condition_id: discount.discount_target_id,
            icon: getIconPath(discount.discount_target_id),
          })
        );
        console.log("discount Condition data======>", newStateCondition);
        setEventDiscountCondition(newStateCondition);
        setEventDiscountTarget(newStateCondition);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/group?limit=${1000}&page=1`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((group) => ({
          value: group.id,
          label: group.group_name,
        }));
        console.log("Fetched groups:", newState); // ตรวจสอบข้อมูลกลุ่ม
        setMenuGroup(newState);
      })
      .catch((err) => {
        console.log("Error fetching groups:", err);
        console.log(err);
      });
  };

  const getMenuNew = async () => {
    let apiUrls = `${baseURL}/management/menu?limit=${1000}&page=1`;
    let token = localStorage.getItem("Token");

    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data;

        // Log ข้อมูลทั้งหมดที่ดึงมา
        console.log("Fetched menu items:", newState);

        setMenuItems(newState);

        // สร้างกลุ่มเมนูใหม่
        let groupMenuMap = {};
        newState.forEach((menu) => {
          if (!groupMenuMap[menu.group_id]) {
            groupMenuMap[menu.group_id] = [];
          }
          groupMenuMap[menu.group_id].push(menu);
        });

        // Log groupMenuMap เพื่อดูการจัดกลุ่มเมนู
        console.log("Grouped menu items by group_id:", groupMenuMap);

        // อัปเดต item_count สำหรับแต่ละกลุ่ม
        setMenuGroup((prevMenuGroup) => {
          const updatedMenuGroup = prevMenuGroup.map((group) => ({
            ...group,
            item_count: groupMenuMap[group.value]
              ? groupMenuMap[group.value].length
              : 0,
          }));

          // Log จำนวนรายการในแต่ละกลุ่มหลังจากการอัปเดต
          console.log(
            "Menu groups with updated item counts:",
            updatedMenuGroup
          );

          return updatedMenuGroup;
        });

        setGroupMenuMap(groupMenuMap);
        setOriginalGroupMenuMap(groupMenuMap); // Store original map for reset
      })
      .catch((err) => {
        console.log("Error fetching menu data:", err);
      });
  };

  useEffect(() => {
    getPromotion();
    getDiscount();
    getGroup();
    getMenuNew();
  }, []);

  function validateDateTime(startDate, expiryDate) {
    // const errors = [];
    const openTimeDate = startDate;
    const closeTimeDate = expiryDate;
    if (openTimeDate > closeTimeDate) {
      alertError(
        `Error: start time (${openTimeDate}) is greater than expiry time (${closeTimeDate}) `
      );
      return false; // Stop further validation if an error is found
    }
    if (openTimeDate === closeTimeDate) {
      alertError(
        `Error: Open time (${openTimeDate}) is the same time of close time (${closeTimeDate}) `
      );
      return false; // Stop further validation if an error is found
    }
    if (
      openTimeDate === null ||
      openTimeDate === undefined ||
      closeTimeDate === null ||
      closeTimeDate === undefined
    ) {
      alertError(`Error: Open time  or closeTime is invalid `);
      return false; // Stop further validation if an error is found
    }
    // if (!(startDate instanceof Date) || !(expiryDate instanceof Date)) {
    //   alertError("Error: Invalid date format");
    //   return false;
    // }
    // if (isNaN(startDate.getTime()) || isNaN(expiryDate.getTime())) {
    //   alertError("Error: Invalid date value");
    //   return false;
    // }
    // if (typeof startDate === "string" || typeof expiryDate === "string") {
    //   alertError("Error: Dates must be valid Date objects, not strings.");
    //   return false;
    // }

    return true; // Validation passed
  }

  const handleImageUpload = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file);

    try {
      const response = await fetch(`${baseURL}/upload/`, {
        method: "POST",
        body: formdata,
      });
      const result = await response.json();
      // const imagePreviewUrl = `https://pos-api.dev.fysvc.com/uploads${result.filename}`;
      const imagePreviewUrl = `${imageURL}/${result.filename}`;
      console.log("Uploaded Image URL:", imagePreviewUrl);

      return imagePreviewUrl; // ส่งคืน URL ของรูปภาพ
    } catch (error) {
      console.log("Error uploading image:", error);
      throw error; // ส่ง error กลับเพื่อใช้ในการตรวจสอบ
    }
  };

  const handleSubmit = async () => {
    if (startDate2  < new Date(startDate2)) {
      Swal.fire({
        icon: "error",
        title: "ข้อผิดพลาด",
        text: "วันที่เริ่มโปรโมชั่นไม่สามารถอยู่หลังวันที่โปรโมชั่นสิ้นสุดได้.",
      });
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create this promotion?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true); 
        const formatDate = (date) => {
          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");
          const hours = String(d.getHours()).padStart(2, "0");
          const minutes = String(d.getMinutes()).padStart(2, "0");
          const seconds = String(d.getSeconds()).padStart(2, "0");
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        if (!validateDateTime(startDate2, expiryDate2)) {
          return;
        }

        let imageUrl;
        if (stateItem_image_path.rawItem_image_path) {
          try {
            imageUrl = await handleImageUpload(
              stateItem_image_path.rawItem_image_path
            );
          } catch (uploadError) {
            console.log("Error during image upload:", uploadError);
            Swal.fire("Error!", "Failed to upload image.", "error");
            return;
          }
        }

        const newSelectedOrderItems = selectedOrderItems.map((id) => ({
          object_id: id,
        }));
        const newSelectedOrderGroups = selectedOrderGroups.map((id) => ({
          object_id: id,
        }));

        let promotionSet = [
          ...newSelectedOrderItems,
          // ...newSelectedOrderGroups,
        ];

        if (discountConditionId == 6) {
          promotionSet = [...newSelectedOrderItems, ...newSelectedOrderGroups];
        }

        console.log("discountConditionId ->", discountConditionId);

        const newSelectedTargetItems = selectedTargetItems.map((id) => ({
          object_id: id,
        }));
        const newSelectedTargetGroups = selectedTargetGroups.map((id) => ({
          object_id: id,
        }));

        let targetItem = [
          ...newSelectedTargetItems,
          // ...newSelectedTargetGroups,
        ];

        if (discountTargetId == 6) {
          targetItem = [...newSelectedTargetItems, ...newSelectedTargetGroups];
        }

        const payload = {
          shop_id: localStorage.getItem("shop_id"),
          branch_id: localStorage.getItem("branch_id"),
          // promotion_name_th: promotionNameTH,
          // promotion_name_en: promotionNameEN,
          // promotion_desc_th: promotionDescTH,
          // promotion_desc_en: promotionDescEN,
          promotion_type_id: promotionTypeId, // step 1_1/1

          discount_type_id: discountTypeId, // step 2_1/3
          // target_item_type: String(discountConditionId), // step 2_2/3
          target_item_type: discountTargetId, // step 2_3/3
          discount_target_id: discountConditionId, // step 2_2/3
          bundle_type_id: selectedBundle, // step 2_4/4

          // selected_order_items: selectedOrderItems.map((id) => ({
          //   items_id: id,
          // })), // step 3_1/6
          // selected_order_groups: selectedOrderGroups.map((id) => ({
          //   items_id: id,
          // })), // step 3_3/6
          promotion_set: promotionSet,

          // selected_target_items: selectedTargetItems.map((id) => ({
          //   items_id: id,
          // })), // step 3_2/6
          // selected_target_groups: selectedTargetGroups.map((id) => ({
          //   items_id: id,
          // })), // step 3_4/6
          target_item: targetItem,
          amount_value: orderQuantity, // step 3_5/8
          target_quantity: targetQuantity, // step 3_6/8
          discount_value: targetFixedPrice, // step 3_7/8
          discount_percent: targetPercent, // step 3_8/8
          promo_img_path: imageUrl, // step 4_1/17 ****
          promotion_name_th: promotionName, // step 4_2/17
          promotion_desc_th: promotionDescription, // step 4_3/17
          type: couponType, // step 4_4/17
          is_dinein: purchaseTypes.isDineIn, // step 4_5/17
          is_pickup: purchaseTypes.isPickUp, // step 4_6/17
          is_take_away: purchaseTypes.isTakeAway, // step 4_7/17
          start_date_time: formatDate(startDate2), // step 4_8/17
          expiry_date_time: formatDate(expiryDate2), // step 4_9/17
          is_day_range: dayType2, // step 4_10/17
          is_time_range: timeType2, // step 4_11/17
          redemption_limit: checkedRedemption ? redemptionDefault : null, // step 4_15/17_12/17
          item_limit_per_day: checkedItemsLimitPerDay
            ? itemsLimitPerDayDefault
            : null, // step 4_16/17_13/17
          is_auto: checkedAutoApply, // step 4_14/17
          order_importance: selectedButton, //step 4_17/17
          is_include_option: checkedIncludeOption, //step 4_18/18
        };
        console.log("payload====>", payload);

        let token = localStorage.getItem("Token");
        try {
          await axios.post(`${baseURL}/management/promotionnew2`, payload, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          Swal.fire({
            title: "Success!",
            text: "Promotion created successfully.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            setIsLoading(false);
            changeStep("next", setIsLoadingPage);
          });
        } catch (err) {
          Swal.fire("Error!", "Failed to create promotion.", "error");
          setIsLoading(false); 
          console.log(err);
        }
        // finally {
        //   setIsLoading(false);
        // }
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <CreatePromotionContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        promotionTypeId,
        setPromotionTypeId,
        promotionTypeName_en,
        setPromotionTypeNameEn,
        promotionTypeName_th,
        setPromotionTypeNameTh,
        discountTypeId,
        setDiscountTypeId,
        discountTargetId,
        setDiscountTargetId,
        discountConditionId,
        setDiscountConditionId,
        discountTypeName_en,
        setDiscountTypeNameEn,
        discountTargetName_en,
        setDiscountTargetNameEn,
        discountConditionName_en,
        setDiscountConditionNameEn,
        discountTypeName_th,
        setDiscountTypeNameTh,
        discountTargetName_th,
        setDiscountTargetNameTh,
        discountConditionName_th,
        setDiscountConditionNameTh,
        menuGroup,
        setMenuGroup,
        menuItems,
        setMenuItems,
        activeStep,
        setActiveStep,
        promotionSet,
        setPromotionSet,
        searchQuery,
        setSearchQuery,
        startDate,
        setStartDate,
        expiryDate,
        setExpiryDate,
        promotionNameTH,
        setPromotionNameTH,
        promotionNameEN,
        setPromotionNameEN,
        promotionDescTH,
        setPromotionDescTH,
        promotionDescEN,
        setPromotionDescEN,
        amountValue,
        setAmountValue,
        discountValue,
        setDiscountValue,
        eventPromotion,
        setEventPromotion,
        eventDiscountType,
        setEventDiscountType,
        eventDiscountCondition,
        setEventDiscountCondition,
        eventDiscountTarget,
        setEventDiscountTarget,
        selectedOrderItems,
        setSelectedOrderItems,
        selectedTargetItems,
        setSelectedTargetItems,
        selectedOrderGroups,
        setSelectedOrderGroups,
        selectedTargetGroups,
        setSelectedTargetGroups,
        groupMenuMap,
        setGroupMenuMap,
        originalGroupMenuMap,
        setOriginalGroupMenuMap,
        orderQuantity,
        setOrderQuantity,
        targetQuantity,
        setTargetQuantity,
        handlePromotionTypeSelect,
        handleNextStep,
        handleBackStep,
        handleDiscountConditionSelect,
        handleDiscountTargetSelect,
        handleDiscountTypeSelect,
        handleOrderQuantityChange,
        handleTargetQuantityChange,
        handleSelectOrderItem,
        handleSelectOrderGroup,
        handleSelectTargetItem,
        handleSelectTargetGroup,
        handleRemoveOrderItems,
        handleRemoveOrderGroups,
        handleRemoveTargetItems,
        handleRemoveTargetGroups,
        promotionName,
        setPromotionName,
        promotionDescription,
        setPromotionDescription,
        alertError,
        couponType,
        setCouponType,
        purchaseTypes,
        setPurchaseTypes,
        purchaseOptions,
        couponOptions,
        nameImageItem_image_path,
        setNameImageItem_image_path,
        item_image_path,
        setItem_image_path,
        stateItem_image_path,
        setStateItem_image_path,
        startDate2,
        setStartDate2,
        expiryDate2,
        setExpiryDate2,
        dayType2,
        setDayType2,
        timeType2,
        setTimeType2,
        checkedRedemption,
        setCheckedRedemption,
        checkedItemsLimitPerDay,
        setCheckedItemsLimitPerDay,
        checkedAutoApply,
        setCheckedAutoApply,
        selectedButton,
        setSelectedButton,
        redemptionDefault,
        setRedemptionDefault,
        itemsLimitPerDayDefault,
        setItemsLimitPerDayDefault,
        handleSelectOrderItemByGroupId,
        handleSubmit,
        targetFixedPrice,
        setTargetFixedPrice,
        targetPercent,
        setTargetPercent,
        handleTargetFixedPriceChange,
        handleTargetPercentChange,
        handleSelectTargetItemByGroupId,
        checkedIncludeOption,
        setCheckedIncludeOption,
        selectedBundle, 
        setSelectedBundle, 
        isLoading,
        setIsLoading,
        isLoadingPage,
        setIsLoadingPage
      }}
    >
      {children}
    </CreatePromotionContext.Provider>
  );
};

export const useCreatePromotionContext = () =>
  useContext(CreatePromotionContext);
